$dark: #3e4954;

.accordion__section {
  display: flex;
  flex-direction: column;

  .accordion {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #eeeeee;
    background-color: #ffffff;
  }

  .accordion__title {
    font-size: 16px;
    font-weight: bold;
    color: $dark;
    padding: 0 30/375 * 100% 0 40/375 * 100%;
    margin-top: 20px;
    margin-bottom: 16px;
    position: relative;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 26/375 * 100%;
      top: 50%;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: translateY(-50%) rotate(135deg);
      -webkit-transform: translateY(-50%) rotate(135deg);
      transition-duration: 0.1s;
    }
  }

  .active .accordion__title {
    &:before {
      transform: translateY(-50%) rotate(-45deg);
      -webkit-transform: translateY(-50%) rotate(-45deg);
      transition-duration: 0.1s;
    }
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.5s ease;
    background-color: #f7f9fc;
  }

  .accordion__text {
    padding: 16px 30/375 * 100%;
    font-size: 14px;
    font-weight: normal;
    color: $dark;
  }

  @media screen and (min-width: 1024px) {
    .accordion {
      margin-top: 20px;
      padding: 14px;
      border-radius: 5px;
    }

    .accordion__title {
      margin-top: 14px;
      margin-bottom: 10px;
      padding-right: 34/1325 * 100%;

      &:before {
        left: 14px;
      }
    }

    .accordion__text {
      padding: 10px 34/1325 * 100%;
    }
  }
}
