.order-page-wrapper {
  direction: rtl;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  padding-top: 64px;
  padding-bottom: 64px;

  .order-wrapper {
    padding: 0 20px;
    max-width: 1325px;
    margin: 0 auto;
  }

  .modal-content {
    width: calc((100% - (14 / 375 * 100% * 2)) - (12px * 2));
  }

  .tranzila-wrapper {
    height: 400px;
  }

  .order-title {
    margin: 10px 0;
    text-align: center;
    color: #bc1e46;
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .order-title span.back:before {
    content: "";
    width: 10px;
    height: 10px;
    position: relative;
    left: 55vw;
    top: -2px;
    border: solid #6e1427;
    border-width: 0 3px 3px 0;
    display: inline-block;
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
  }

  .order-list {
    margin: 10px 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    position: relative;
  }

  .order-list-details {
    display: block;
    padding: 22px 15px 2px;
    color: #565152;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: right;
  }

  .order-list-info {
    padding: 10px 15px;
    background-color: #f5f5f5;
  }

  .order-list-info div {
    display: flex;
    justify-content: space-between;
    color: #3e4954;
  }

  .order-list-info div span {
    color: #3e4954;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  // .order-list-info div:first-child {
  //   font-size: 16px;
  //   border-bottom: 1px solid #dbdbdb;
  // }

  .order-list-info div:last-child span {
    color: #bc1e46;
    font-weight: bold;
  }

  .order-list-info-desctop {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: var(--red);
    padding: 20px 15px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;

    div {
      padding: 20px 0;
    }

    div:first-child {
      border-left: 1px solid #c4c4c4;
      font-size: 18px;
      color: #3e4954;
      margin-left: 0;
      margin-right: auto;
    }

    div:last-child {
      color: #bc1e46;
      font-weight: bold;
    }
  }

  .order-list-info-optional-sum {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: right;
    color: var(--dark);
  }

  .order-list-info-count {
    font-size: 18px !important;
    color: #c4c4c4 !important;
  }

  .order-item-index {
    font-family: OpenSansHebrew;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: right;
    color: var(--dark);
  }

  .order-item-wrapper {
    padding: 10px 0;
    margin: 0 15px;
  }

  .order-item-wrapper.bordered-desctop, .order-item-wrapper.bordered {
    border-bottom: 2px solid #f4f4f4;
  }

  .order-item {
    border-bottom: none;
  }

  .order-item-content {
    display: flex;
    
  }

  .order-item-info {
    margin-right: 3%;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: right;

    > p {
      margin-bottom: 0;
    }
  }

  .order-item-info-category {
    color: #c4c4c4;
  }

  .order-item-title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #515b65;
  }

  .order-item-title {
    margin-top: 10px;
    height: 27px;
    font-family: OpenSansHebrew;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: right;
    color: var(--dark);
  }

  .order-item-title.desctop {
    margin-top: 10px;
    height: 27px;
    font-family: OpenSansHebrew;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: right;
    color: var(--dark);
  }

  // .order-item-menu {
  //   position: fixed;
  //   margin-top: 14%;
  //   margin-left: 23%;
  //   width: 180px;
  //   height: 100%;
  //   z-index: 10;
  // }

  // .order-item-menu-desctop {
  .order-item-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    z-index: 10;
  }

  .order-item-close-btn {
    margin-left: 0;
    margin-right: auto;
    width: 10px;
    height: 10px;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .order-item-menu-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    // height: 30px;
    padding: 8px 0;
    text-align: center;
    color: #898989;
    border-radius: 5.9px;
    background-color: #f5f5f5;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .order-item-menu-item__text {
    margin: 0 auto;
  }

  .order-item-menu-item__icon {
    padding-right: 10px;
  }

  // .order-item-menu-content {
  //   display: flex;
  //   flex-direction: column;
  //   transform: translate(-50%, -50%);
  //   width: 180px;
  //   max-width: 100%;
  //   max-height: 100%;
  //   border: 1px solid #ccc;
  //   background: #fff;
  //   overflow: auto;
  //   border-radius: 10px;
  //   box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
  //   outline: none;
  //   padding: 12px;
  // }

  // .order-item-menu-item-desctop {
  //   margin-top: 10px;
  //   width: 100%;
  //   height: 30px;
  //   text-align: center;
  //   color: #898989;
  //   border-radius: 5.9px;
  //   background-color: #f5f5f5;

  //   user-select: none;
  //   cursor: pointer;
  //   -webkit-tap-highlight-color: transparent;
  // }

  // .order-item-menu-content-desctop {
  .order-item-menu-content {
    display: flex;
    flex-direction: column;
    width: 180px;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    outline: none;
    padding: 12px;
  }

  .order-item-img {
    margin: 0 1.5%;
    width: 12%;

    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }

  .order-item-img.desctop {
    display: flex;
    align-items: center;
  }

  .order-item-footer {
    display: flex;
  }

  .vertical-points-wrapper {
    margin-top: 6px;
    position: relative;
  }
  .order-buttons {
    width: 2.15rem;
  }

  .vertical-points-wrapper.desctop {
    margin-right: auto;
    margin-left: 0;
  }

  .vertical-points {
    width: 4px;
    height: 4px;
    background: #ababab;
    border-radius: 2px;
  }

  .vertical-points:nth-child(2) {
    margin: 4px 0;
  }

  .counter {
    display: flex;
    justify-content: space-between;
    width: 70px;
    height: 24px;
    margin-right: 10px;
    border-radius: 55px;
    background-color: #f1f1f1;
  }

  .counter.desctop {
    margin-top: 17%;
    margin-right: 100px;
  }

  .counter a {
    display: block;
    width: 35%;
    line-height: 24px;
    text-align: center;
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
  }

  .counter span {
    display: block;
    width: 30%;
    height: 20px;
    line-height: 20px;
    margin: auto 0;
    text-align: center;
    background-color: #ffffff;
  }

  .order-title-desktop {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    text-align: right;
    color: #BC1E46;
    margin-bottom: 60px;
  }

  .details-and-payments {
    margin: 10px 0;
    text-align: center;
    color: #3E4954;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .confirm-order-button {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background-color: #bc1e46;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    z-index: 11;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .confirm-order-button-desctop {
    display: block;
    width: 100%;
    border-radius: 10px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background-color: #bc1e46;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .confirm-order-button:before {
    content: "";
    width: 8px;
    height: 8px;
    position: relative;
    left: -14px;
    top: -2px;
    border: solid #ffffff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .confirm-order-button:after {
    content: "";
    width: 8px;
    height: 8px;
    position: relative;
    left: -227px;
    top: -2px;
    border: solid #ffffff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .order-item-separate {
    display: flex;
    margin-left: 0;
    margin-right: auto;
  }

  .order-item-quantity {
    align-self: flex-end;
  }

  .order-item-price.desctop {
    width: 59px;
    height: 25px;
    font-family: OpenSansHebrew;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: right;
    color: var(--dark);
  }

  .order-column-headers {
    display: flex;
    padding: 22px 0;
    border-bottom: 2px solid #f4f4f4;
    font-family: OpenSansHebrew;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: var(--purplish-brown);
  }

  .order-info {
    direction: rtl;
    padding-bottom: 51px;
  }

  // for descktop
  @media screen and (min-width: 1024px) {
    .order-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
    }

    .desktop-only {
      display: block;
    }

    .modal-content {
      width: calc((651 / 1500 * 100% - (14 / 375 * 100% * 2)) - (12px * 2));
    }

    .error-wrapper {
      .modal-content {
        width: calc((348 / 1500 * 100% - (14 / 375 * 100% * 2)) - (12px * 2));
      }

      .confirmation-window-primary-text {
        font-size: 18px;
      }
    }

    .order-item-content {
      align-items: center;
    }

    .order-item-quantity {
      width: 22%;
      align-self: auto;
    }

    .order-item-info {
      width: 45%;
    }

    .order-list {
      margin: 176px 18px 0 26.3px;
      width: 850px;
    }
    .order-list-details {
      padding: 10px 28px;
    }
    .order-list-info {
      display: flex;
      flex-direction: row;
    }
    .order-info {
      padding-bottom: 0;
      margin-top: 36px;
      width: 500px;
    }

    .details-and-payments {
      text-align: right;
    }
  }

  @media screen and (max-width: 1000px) {
    .order-item-wrapper {
      height: 100%;
    }
  }

  // .--error {
  //   border: solid 1px #e24047;
  // }

  .--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
