.decorative-image img {
  height: 38vh;
  object-fit: contain;
}

.get-on-mobile-panel {
  display: flex;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f0f0f0;
  font-size: 14px;
  padding: 2%;
  margin-bottom: 1%;
}

.get-on-mobile-panel > img {
  height: 100%;
}

.login-panel-wrapper {
  min-width: 320px;
  width: 80%;
  max-width: 400px;
  margin: -4% auto 0;
  border-radius: 15px;
  background: transparent;
  position: relative;
}

.login-panel {
  background-color: white;
  border-radius: 15px;
  padding: 11/671 * 100% 21/375 * 100% 17/671 * 100%;
  box-shadow: 3px 3px 3px 3px #ccc;
}

.first-row {
  display: flex;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  color: #bc1e46;
  position: relative;
}

.titles {
  overflow-wrap: break-word;
  width: 50%;
}

.full-title {
  display: none;
}

.dish-tag {
  text-align: center;

  #full-dish-tag {
    display: none;
  }
}
.lang-switcher-auth {
    background: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}
.lang-he {
    left: 0;
    right: auto;
}
.lang-en {
    left: auto;
    right: 0;
}
.enter-contact-data-panel .dish-tag {
  width: 50%;
  margin-left: 10px;
  text-align: left;
}

.enter-otp-panel .dish-tag {
  margin-bottom: 57px;
  margin-top: 20px;
  position: relative;

  .dish-tag img {
    width: 148px;
  }
}

.first-row .title {
  font-weight: bold;
}

.info-message {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #bc1e46;
  direction: rtl;
}

.input-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 1em;

  > div {
    width: 100%;
    text-align: center;
    margin-top: 13px;
    margin-bottom: 8px;
  }

  .or-column {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #6e1427;
    height: 22px;
  }
  .or-column span {
    background-color: white;
    z-index: 1;
    position: absolute;
    padding: 0 10px;
    transform: translateX(-50%);
  }
  .or-column::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    transform: translateX(-50%);
  }

  > div:first-child,
  > div:last-child {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #6e1427;
  }

  input {
    height: 49px;
    padding-right: 40px;
    width: calc(100% - 40px);
  }
}

.input-field-wrapper {
  position: relative;
}

.input-type-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.invalid-input-field {
  input {
    border: solid 1px #e24047;
  }

  label {
    color: #e24047;
  }

  .invalid-input-icon {
    margin-left: 7px;
    vertical-align: middle;
    display: initial;
  }
}

.invalid-input-icon {
  display: none;
}

.otp-input {
  height: 64px;
  background: url(../../images/login/otp_icon.svg) no-repeat scroll right 32px
    center;
  padding-right: 66px;
  width: calc(100% - 66px);
  border: 0;
}

.middle-row {
  display: block;
  margin: 30px 0;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.send-again-link {
  margin-right: 30px;

  a {
    font-size: 14px;
    color: #6e1427;
  }
}

.otp-resend-button.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.otp-is-recend-message {
  font-weight: bold;
  line-height: 1.29;
  color: #6e1427;

  > img {
    vertical-align: middle;
  }
}

.contact-data-displayed {
  font-size: 17px;
  font-weight: bold;
  color: #6e1427;
  margin-bottom: 32px;
  width: 100%;
  text-align: center;
  overflow-wrap: break-word;
  position: relative;

  > img {
    vertical-align: middle;
    margin-left: 10px;
  }

  > input {
    font-size: 17px;
    font-weight: bold;
    color: #6e1427;
    background-color: white;
    text-align: center;
    height: auto;
  }
}

.lowest-row {
  display: flex;
  align-items: center;
  position: relative;

  button {
    border-radius: 30px;
    padding: 0;
    height: 51px;
  }
}
.lowest-row .common-border-row button {
    height: 38px;
    margin-left: 3px;
    width: 132px;
}

.common-border-row {
  border-radius: 100px;
  border: solid 1px #e3e3e3;
  height: 64px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  label {
    color: #6e1427;
    width: 100%;

    span {
      font-size: 12px;
      position: absolute;
      top: -10px;
      right: 40px;
      text-align: right;
      background-color: white;
    }
  }

  > input {
    border: 0;
  }

  > button {
    margin: 7px;
    width: 111px;
    z-index: 1;
  }
}

.common-border-row.invalid-input-field {
  border: solid 1px #e24047;

  label {
    color: #e24047;
  }

  input {
    border: 0;
  }
}

.page-wrapper {
  .error-window {
    position: absolute;
    bottom: 0;
    left: -12px;
    right: -12px;
    text-align: center;
  }

  .error-tag {
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 1024px) {
  .page-wrapper {
    height: 100vh;
    border-right: 25px solid #bc1e46;
  }

  .decorative-image img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    vertical-align: top;
  }

  .login-panel-wrapper {
    position: absolute;
    min-width: auto;
    max-width: 700px;
    min-height: 295px;
    top: auto;
    left: auto;
    right: 10%;
    bottom: 10%;
    margin: 0;
    border-radius: 15px;
    box-shadow: 3px 3px 3px 3px #ccc;
    overflow: hidden;
  }

  .get-on-mobile-panel {
    display: none;
  }

  .login-panel {
    background-color: white;
    padding-left: 46px;
    padding-right: 64px;
    padding-top: 47px;
    padding-bottom: 42px;
    border-radius: 15px;
  }

  .enter-otp-panel .info-message {
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    text-align: right;
    color: #bc1e46;
  }

  .dish-tag {
    text-align: right;

    > #full-dish-tag {
      display: block;
    }

    > #small-dish-tag {
      display: none;
    }
  }

  .enter-contact-data-panel .dish-tag {
    width: auto;
  }

  .enter-otp-panel {
    .dish-tag {
      margin-top: 0;
      margin-bottom: 19px;
    }

    .dish-tag img {
      width: auto;
    }
  }

  .titles {
    position: relative;
    width: auto;
  }

  .full-title {
    display: block;
  }

  .short-title {
    display: none;
  }

  .first-row {
    flex-direction: column;
    color: #bc1e46;

    .title {
      font-size: 42px;
      font-weight: bold;
      line-height: 1.19;
    }

    .after-title {
      font-size: 37px;
      line-height: 1.35;
    }
  }

  .middle-row {
    margin: 48px auto 98px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
    }
  }

  .contact-data-displayed {
    font-size: 30px;
    overflow-wrap: break-word;
    width: 70%;
    margin: 0;

    > input {
      font-size: 30px;
      height: auto;
    }
  }

  .input-wrapper {
    margin-top: 17px;
    margin-bottom: 48px;
    flex-direction: row;

    .or-column {
      width: 10%;
      margin-top: 1.5em;
      height: auto;

      span {
        padding: 0;
        transform: translate(-50%, -20%);
      }
    }
    .or-column::after {
      top: -21px;
      left: 50%;
      width: auto;
      height: 53px;
      border-left: 1px solid#d8d8d8;
      transform: translate(-50%);
    }
    > div:first-child,
    > div:last-child {
      width: 45%;
    }
  }

  .lowest-row button {
    font-size: 22px;
    border-radius: 30px;
  }

  .lowest-row .common-border-row button {
    height: 38px;
    margin-left: 3px;
    width: 132px;
  }

  .common-border-row {
    width: 382px;

    label span {
      font-size: initial;
    }
  }

  .error-window {
    padding: 11px 31px 16px;
    top: 0;
    text-align: right;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.66);
  z-index: 1050;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
  outline: none;
  padding: 12px;
}

.terms-tag {
  margin-top: 30px;
  text-align: center;
}

.modal-content .info-row {
  margin-top: 20px;
  margin-bottom: 25px;
  height: 30%;
  text-align: center;
  font-size: 18px;
  line-height: 1.11;
  color: #6e1427;
}

.link-to-terms-row {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.11;
  color: #6e1427;
  height: 25px;
}

.link-to-terms-row a {
  color: #6e1427;
}

.error-window .info-row {
  font-size: 16px;
  a {
    color: #6e1427;
  }
}

.checkbox-wrapper {
  vertical-align: middle;
  position: relative;
  margin-left: 10px;
}

.checkbox-check {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
}

.not-checked {
  display: none;
}

.checked {
  display: block;
}

.terms-confirm-button {
  border-radius: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.88;
  width: 100%;
  height: 47px;
}
