.menu-wrapper {
    display: block;

    .menu-bg {
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        right: 0;
        top: 0;
        z-index: 101;

        .menu {
            width: 342px;
            height: 100vh;
            background: #fff;
            z-index: 10;
            position: fixed;
            right: 0;
            top: 0;
            transform: translateX(0);
            transition: transform 0.4s ease-in;

            .menu-content {
                padding: 23px 15px;

                .menu-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                        cursor: pointer;
                    }
                }

                .menu-name__logo {
                    margin: 23px 0;
                    display: flex;
                    justify-content: flex-end;
                }

                .menu-nav {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .menu-item span {
                        position: absolute;
                        text-align: center;
                        bottom: 0;
                    }

                    .menu-item {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        min-width: 48%;
                        height: 80px;
                        border-radius: 6px;
                        border: solid 1px #d3d3d3;
                        padding: 20px 0;
                        margin-bottom: 16px;
                        color: black;

                        .menu-item-img {
                            height: 34px;
                            display: flex;
                            margin-bottom: 21px;
                        }
                    }

                    .menu-item.active {
                        background-color: #fcf6f8;
                    }

                    .menu-item.active:before {
                        content: " ";
                        position: relative;
                        top: -35px;
                        width: 85%;
                        border-top: 3px solid #bc1e46;
                        border-radius: 10px 10px 0 0;
                    }
                }
            }

            .menu-footer {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                padding-bottom: 50px;

                .menu-description {
                    padding: 18px 25px;
                    background-color: #f7f9fc;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .links-wrapper {
                        margin-right: 0;
                        margin-left: auto;
                    }

                    .menu-terms {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: black;
                        text-decoration: none;

                        span {
                            margin-right: 20px;
                            font-size: 15px;
                        }
                    }
                }

                .menu-logout {
                    display: flex;
                    padding: 14px 25px;
                    justify-content: flex-end;

                    > span {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                    }

                    img {
                        margin-left: 20px;
                    }
                }

                .menu-logout-reverse {
                    display: flex;
                    flex-direction: row-reverse;
                    padding: 14px 25px;
                    justify-content: flex-end;

                    > span {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                    }

                    img {
                        margin-left: 20px;
                    }
                }
            }
        }

        .menu-reverse {
            width: 342px;
            height: 100vh;
            background: #fff;
            z-index: 10;
            position: fixed;
            left: 0;
            top: 0;
            transform: translateX(0);
            transition: transform 0.4s ease-in;

            .menu-content {
                padding: 23px 15px;

                .menu-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                        cursor: pointer;
                    }
                }

                .menu-name__logo {
                    margin: 23px 0;
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                }

                .menu-nav {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .menu-item span {
                        position: absolute;
                        text-align: center;
                        bottom: 0;
                    }

                    .menu-item {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        min-width: 48%;
                        height: 80px;
                        border-radius: 6px;
                        border: solid 1px #d3d3d3;
                        padding: 20px 0;
                        margin-bottom: 16px;
                        color: black;

                        .menu-item-img {
                            height: 34px;
                            display: flex;
                            margin-bottom: 21px;
                        }
                    }

                    .menu-item.active {
                        background-color: #fcf6f8;
                    }

                    .menu-item.active:before {
                        content: " ";
                        position: relative;
                        top: -35px;
                        width: 85%;
                        border-top: 3px solid #bc1e46;
                        border-radius: 10px 10px 0 0;
                    }
                }
            }

            .menu-footer {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                padding-bottom: 50px;

                .menu-description {
                    padding: 18px 25px;
                    background-color: #f7f9fc;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row-reverse;

                    .links-wrapper {
                        margin-right: auto;
                        margin-left: 20px;
                    }

                    .menu-terms {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: black;
                        text-decoration: none;

                        span {
                            margin-right: 20px;
                            font-size: 15px;
                        }
                    }
                }

                .menu-logout {
                    display: flex;
                    padding: 14px 25px;
                    justify-content: flex-start;

                    > span {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        flex-direction: row-reverse;
                    }

                    img {
                        margin-right: 20px;
                    }
                }

                .menu-logout-reverse {
                    display: flex;
                    flex-direction: row-reverse;
                    padding: 14px 25px;
                    justify-content: flex-end;

                    > span {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                    }

                    img {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}


.menu-wrapper.close {
    .menu-bg {
        width: 0;
    }

    .menu {
        transform: translateX(342px);
    }

    .menu-reverse {
        transform: translateX(-342px);
    }
}

@media screen and (min-width: 1024px) {
    .menu-wrapper {
        display: none;
    }
}

@media (max-height: 660px) {
    .menu-wrapper {
        .menu-bg {
            .menu {
                .menu-content {
                    .menu-nav {
                        .menu-item {
                            height: 60px;
                        }

                        .menu-item.active:before {
                            top: -25px;
                        }
                    }
                }
            }
        }
    }
}


.dropdown-menu {
    position: relative;
    display: inline-block;
    border: 1px solid;
}

.dropdown-handler-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.menu-reverse {
    .dropdown-handler-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-transform: capitalize;
}

.dropdown-title {
    margin-left: 8px;
    margin-right: 8px;
}

.dropdown-list {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    transition: height 0.3s ease;
    bottom: 60%;
}

.dropdown-item {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;
}



.enLang-img {
    padding: 10px 12px;
    font-size: 14px;
    background: url("../../images/en.svg") no-repeat;
}

.heLang-img {
    padding: 10px 12px;
    font-size: 14px;
    background: url("../../images/he.svg") no-repeat;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}