//----------------------------------------------------------

.main-page-content-wrapper .modal-content {
    width: calc((100% - (14 / 375 * 100% * 2)) - (12px * 2));
    direction: rtl;
}

.confirmation-window-close-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.confirmation-window-text {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: #666f80;
}

.confirmation-window-text .value {
    margin-left: 0.5rem;
}

.confirmation-window-text .item {
    display: inline-flex;
}

.button.confirmation-window-footer-btn {
    margin: auto;
}

.confirmation-window-primary-text {
    margin: 0;
    font-size: 25.6px;
    font-weight: bold;
    line-height: 1.95;
    text-align: center;
    color: #bc1e46;
}

.confirmation-window-secondary-text {
    font-size: 18px;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #666f80;
}

.confirmation-window-footer {
    margin-top: 14px;
}

.confirmation-window-footer button {
    width: calc((50%) - 8px);
    min-height: 47px;
    border-radius: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.dish-information .tag-wrapper .tag {
    margin-top: 5px;
    margin-left: 12px;

    &:last-child {
        margin-left: 0;
    }
}
//----------------------------------------------------------

.main-page-content-wrapper,
.main-wrapper {
    direction: rtl;
}

.main-wrapper-reverse {
    direction: rtl;
}

.configurations {
    flex-direction: column;
}

.configurations .configuration {
    border-bottom: 1px solid #dedede;
    position: relative;
    height: 48px;
}

.cursor-pointer {
    cursor: pointer;
}

.configurations .configuration a {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 48px;
    padding-right: 30px;
    text-align: right;
    margin: 0 20px;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.configurations-reverse .configuration a {
    flex-direction: row-reverse !important;
    padding-right: 0px !important;
    padding-left: 30px !important;
}

.configurations .configuration .list-item a {
    padding-right: 0;
    margin: 0;
}

.configurations > .configuration > a {
    z-index: 100;
    padding-right: 30px;
}

.drop-down-container {
    display: flex;
    padding: 0 1rem;
}

.drop-down-container > a {
    width: 100%;
    margin: 0 !important;
}

.configurations > .configuration > .drop-down > a:before,
.drop-down-container > a:before {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    left: 3%;
    top: 20px;
    border: solid #cacfd9;
    border-width: 3px 3px 0 0;
    display: inline-block;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transition-duration: 0.1s;
}


.configurations-reverse > .configuration > .drop-down > a:before,
.drop-down-container > a:before {
    right: 0 !important;
    left: auto !important;
}
.configurations-reverse > .configuration > .drop-down > a:after,
.drop-down-container > a:after {
    left: 0 !important;
    right: auto !important;
}

.configurations > .configuration > .drop-down > a:after,
.drop-down-container > a:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
}

.configurations .user > .drop-down > a:after {
    background-image: url("../../images/user_icon_small.svg");
}

.configurations .location > .drop-down > a:after {
    background-image: url("../../images/location_icon_red.svg");
}

.configurations .meal-delivery > .drop-down > a:after {
    background-image: url("../../images/location_icon_red.svg");
}


.configurations .item > .drop-down > a:after {
    background-image: url("../../images/history_icon.svg");
}

.configurations .calendar > .drop-down > a:after {
    background-image: url("../../images/calendar_icon_small.svg");
}

.configurations .budget > .drop-down > a:after {
    background-image: url("../../images/user_icon_small.svg");
}

.configurations .types {
    display: none;
}

.list-calendars-tags {
    display: flex;
    flex-direction: row;
}

.list-calendars-tag {
    margin: 5px;
    border-radius: 100px;
    border: solid 1px #bc1e46;
    color: #bc1e46;
    padding: 0 10px 0 10px;
}

.configurations .configuration ul {
    max-height: 0;
    margin: 0;
    transform-origin: top;
    transform: scaleY(0);
    transition: 0.5s;
    z-index: 1000;
    padding: 0;
}

.configurations .configuration ul li {
    list-style-type: none;
    border-top: 1px solid #eeeeee;
    border-right: 9px solid transparent;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.configurations-reverse .configuration ul li {
    border-right: 0px !important;
    border-left: 9px solid transparent;
}

.self-user {
    background-color: #f1f1f1;
}

.configurations .configuration ul li.active {
    color: #3e4954;
    border-right-color: #bc1e46;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    background: linear-gradient( to left, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) );
}
.configurations-reverse .configuration ul li.active {
    border-left-color: #bc1e46;
    background: linear-gradient( to right, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) ) !important;
}

.configurations .configuration .drop-down,
.drop-down .drop-down-container {
    transition: background-color 0.1s step-end 0.5s;
}

.configurations .configuration .drop-down.expanded,
.drop-down.expanded .drop-down-container, {
    transition: background-color 0s step-start;
}

.configurations .configuration .drop-down.expanded > a,
.drop-down.expanded .drop-down-container > a {
    font-size: 18px;
    font-weight: bold;
    color: #3e4954;

    &:before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition-duration: 0.1s;
    }
}

.configurations .configuration .drop-down.expanded ul,
.drop-down.expanded .drop-down-container ul {
    max-height: 50vh;
    overflow: auto;
    height: 100%;
    transform: scaleY(1);
    transition: 0.5s;
}

.configurations .configuration .drop-down ul li:last-child {
    border-bottom: none;
}

.order-wrapper {
    padding: 20px 16px;
}

.search {
    display: flex;
    justify-content: space-between;
    margin: 10px 16px;
    order: 2;
}

.search .settings-button {
    display: block;
    width: 68px;
    height: 55px;
    background-image: url(../../images/settings_icon.svg);
    background-position: left -4px top -3px !important;
}

.search .search-field {
    display: block;
    margin-left: 12px;
    text-align: right;
    height: 47px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    background-image: url(../../images/search_icon.svg);
    background-position: left -14px top -5px !important;
    background-repeat: no-repeat !important;
}

.search-reverse {
    display: flex;
    justify-content: space-between;
    margin: 10px 16px;
    order: 2;
}

.search-reverse .settings-button {
    display: block;
    width: 68px;
    height: 55px;
    background-image: url(../../images/settings_icon.svg);
    background-position: right -4px top -3px !important;
}

.search-reverse .search-field {
    display: block;
    margin-right: 12px;
    text-align: left;
    height: 47px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    background-image: url(../../images/search_icon.svg);
    background-position: right -14px top -5px !important;
    background-repeat: no-repeat !important;
}

.order-countdown-reverse {
    background-color: #6e1427;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    flex-direction: row-reverse;

    > span {
        margin-right: auto;
        margin-left: auto;
    }

    > img {
        margin-right: 10px;
        margin-left: 15px;
    }
}

.order-countdown {
    background-color: #6e1427;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
    color: #ffffff;

    > span {
        margin-right: auto;
        margin-left: auto;
    }

    > img {
        margin-right: 15px;
        margin-left: 10px;
    }
}


.list {
    direction: rtl;
    display: flex;
    overflow-y: hidden;

    .checkbox {
        display: none;
    }
}

.list::-webkit-scrollbar {
    display: none;
}

.title.price.ranges {
    display: block;
    text-align: right;
    padding-right: 65px;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    color: #cacfd9;
    position: relative;
    background-color: #ffffff;
}

.meal-type,
.meal-delivery,
.calendars,
.list-title {
    display: none;
}
.list-title.reverse {
    display: none;
}

.list-item.active {
    color: #3e4954;
    border-right-color: #bc1e46;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    background: linear-gradient( to left, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) );
}

.list-item.active.reverse {
    color: #3e4954;
    border-left-color: #bc1e46;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    background: linear-gradient( to right, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) ) !important;
}

.list-item.reverse {
    border-right: none !important;
    border-left: 9px solid transparent;
}
.mealType-reverse {
    flex-direction: row-reverse;
}

.list .list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #6e1427;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
    border-radius: 100px;
    margin: 8px;
}

.list-price.active {
    color: #FFFFFF;
    border-right-color: #bc1e46;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    background: #BC1E46;
    min-width: 95px;
}

.list-price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #6e1427;
    border-radius: 100px;
    margin: 8px;
    min-width: 95px;
    border: 1px solid rgba(225, 225, 225, 0.5);
    height: 3rem;
}


.list .list-item span {
    display: flex;
    width: auto;
    margin-left: 1rem;
    padding-right: 0.25rem;
    white-space: nowrap;
}

.list .list-item.active {
    background-color: #bc1e46;
    color: #ffffff;
}

.list .list-price span {
    display: flex;
    width: auto;
    margin-left: 1rem;
    padding-right: 0.25rem;
    white-space: nowrap;
}

.list-price .checkbox {
    display: none !important;
}

.list .list-price.active {
    background-color: #bc1e46;
    color: #ffffff;
}

.currency-icon {
    margin: 0.25rem;
}

.dishes-list-title {
    display: none;
}
.dishes-list-title-reverse {
    display: none;
}


.ordered-item-block {
    margin: 0 16px 10px;
    box-shadow: 0 2px 16px 0 rgba(54, 61, 77, 0.14);
    background-color: #ffffff;
    display: flex;

    .ordered-item-block-image {
        margin: 16px;

        > img {
            width: 80px;
            max-height: 100%;
        }
    }

    .ordered-item-block-info {
        width: calc(100% - 70px - 106px);
    }

    .ordered-item-block-name {
        font-size: 18px;
        font-weight: bold;
        color: #565152;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .ordered-item-block-location {
        font-size: 14px;
        color: #666f80;
    }

    .ordered-item-block-item {
        font-size: 14px;
        color: #666f80;
    }

    .ordered-item-block-actions {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: center;
        color: #6e1427;
        border-top: 1px solid #d3d6e4;
        user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        > div {
            padding: 0 6px;
            display: flex;

            > img {
                width: 10px;
                margin-right: 5px;
            }
        }

        > div:nth-child(2) {
            width: 1px;
            height: 12px;
            background-color: #d3d6e4;
            padding: 0;
        }
    }

    .ordered-item-block-status {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #bc1e46;
        margin: auto;
    }
}

.dishes-list-wrapper {
    margin: 10px 16px;
}

.dishes-list-wrapper-reverse {
    margin: 10px 16px;
}

.dishes-list-date-wrapper {
    width: 60%;
    margin-top: 0.5rem;
    padding-right: 2rem;
    max-width: 450px;
}

.dish {
    width: 100%;
    margin: 10px 0px;
    border-radius: 5px;
    box-shadow: 0 2px 16px 0 rgba(54, 61, 77, 0.14);
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.dish-favorite {
    z-index: 1;
    text-align: left;
    margin-left: 15px;
    height: 0px;
    position: relative;
    top: 135px;
}

.dish-favorite-reverse {
    z-index: 1;
    text-align: right;
    margin-right: -13px;
    height: 0px;
    position: relative;
    top: 135px;
}



.banner {
    position: static;
    left: 0.35px;
    top: 0px;
    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 17px 0px;
}

.banner-base {
    position: static;
    left: calc(50% - 1143px/2);
    top: 10px;
    /* CTA */

    background: #BC1E46;
    box-shadow: 0px 2px 16px rgba(54, 61, 77, 0.14);
    border-radius: 5px;
    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0px 14px;
}


.deskTopBanner {
    direction: ltr !important;
    padding: 16px;
    max-height: 312px !important;

    .carousel-slider {
        box-shadow: 0px 2px 16px rgba(54, 61, 77, 0.14);
        border-radius: 5px;
        max-height: 312px !important;
    }

    .carousel-inner {
        max-height: 312px;
        box-shadow: 0px 2px 16px rgba(54, 61, 77, 0.14) !important;
        border-radius: 5px;
    }

    .carousel-caption {
        > h5 {
            font-size: 42px;
        }

        > p {
            font-size: 22.5px;
        }
    }
}

.bannersCarouselMobile {
    direction: ltr !important;
    padding: 16px;
    max-height: 280px !important;
    margin-bottom: 20px;

    .carousel-slider {
        box-shadow: 0px 2px 16px rgba(54, 61, 77, 0.14);
        border-radius: 5px;
        max-height: 280px !important;
    }

    .carousel-inner {
        max-height: 280px;
        box-shadow: 0px 2px 16px rgba(54, 61, 77, 0.14) !important;
        border-radius: 5px;
    }

    .carousel-caption {
        > h5 {
            font-size: 22px;
        }

        > p {
            font-size: 12px;
        }
    }
}

.carousel-caption {
    top: 5px;
    color: #FFFFFF;
    font-family: 'OpenSansHebrew';
}

.price-item {
    background-color: white;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.carousel-indicators {

    > button {
        background-color: #6E1427 !important;
        background-clip: none !important;
        width: 7px !important;
        height: 7.3px !important;
        border-radius: 50%;
        border: 0px !important;
        margin-bottom: 10px !important;
    }

    .active {
        background-color: white !important;
    }
}

.bannerImage {
    min-height: 312px !important;
}

.mobileImage {
    min-height: 280px !important;
}

.dot {
    background: #6E1427 !important;
    opacity: 1 !important;
}

.selected {
    background: #FFFFFF !important;
}

.MuiFormControlLabel-root {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 90%;
}

.MuiCheckbox-root {
    color: rgba( 110, 20, 39, 1) !important;
    opacity: 1 !important;
}

.dish-image-wrapper {
    position: relative;
    text-align: center;
}

.dish-image-blocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(24, 24, 24, 0.66);
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

.dish-image-text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.dish-image {
    width: 100%;
    background-repeat: no-repeat !important;
    object-fit: cover;
    height: 180px;
}

.dish-image-tags {
    position: absolute;
    top: 16px;
    right: 20px;
    display: flex;
}

.dish-image-tags-reverse {
    position: absolute;
    top: 16px;
    left: 20px;
    display: flex;
}

.dish-image-tag {
    display: block;
    padding: 0 10px;
    height: 20px;
    margin: 0 5px;
    line-height: 20px;
    text-align: center;
    color: #6e1427;
    font-size: 12px;
    border-radius: 30px;
    background-color: #ffffff;
}

.dish-image-filler {
    width: 120/360 * 100%;
    object-fit: fill;
}

.dish-information {
    padding-right: 25px;
    padding-left: 10px;
    text-align: right;
}

.dish-information-reverse {
    padding-left: 25px;
    padding-right: 10px;
    text-align: left;
}

.dish-name {
    display: block;
    padding: 5px 0 5px 0;
    color: #6e1427;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
}

.dish-restaurant {
    display: block;
    padding: 5px 0 10px 0;
    color: #666f80;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.dish-cost {
    display: flex;
    color: #6e1427;
    padding: 10px 0 10px 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border-top: 1px solid #e0e0e0;
}

.dish-cost__icon {
    margin-left: 12px;
}

.dish-price-and-actions-row {
    display: flex;
    padding-bottom: 10px;
}

.dish-price-and-actions-row .order-button {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    padding: 0 15px;
    border-radius: 15.5px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    background-color: #bc1e46;
    color: #ffffff;
    font-size: 12px;
}

.order-button__cart-icon {
    margin-left: 6px;
}

.main-order-wrapper {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 1002;
}

.main-page-footer {
    width: 100%;
    background-color: #6e1427;
    color: white;
    text-align: center;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
}

.main-page-footer-wrapper {
    margin-right: 80px;
    display: flex;
    flex-direction: column;
}

.main-page-footer-link {
    margin-left: 5%;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.main-page-footer-link > a {
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
}

.main-page-footer-link > a:hover {
    color: #ffffff;
}

.main-page-footer-span {
    font-size: 8px;
}

.main-page-footer-img {
    justify-content: flex-end;
}

.main-order-wrapper .order-item-info-wrapper {
    background: #fafafa;
}

.main-combo-wrapper.main-order-wrapper .order-item-info-wrapper {
    background: #fafafa;
    height: 100%;
}

.configuration.expanded-parent {
    position: absolute;
    z-index: 1000;
}

.list-calendars-tags-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.drop-down .search .drop-down-search {
    display: none;
}

.configurations .configuration .drop-down .drop-down-search {
    display: none;
}

.configurations .configuration .drop-down.expanded .drop-down-search {
    display: initial;
    margin: 0;

    .search-field {
        width: calc(100% - 40px);
        margin: 10px 20px;
    }
}

@media screen and (max-width: 1023px) {
    .hidden-on-mobile {
        display: none;
    }

    .list-price {
        min-width: 125px;
    }

    .list-price.active {
        min-width: 125px;
    }

    .header {
        height: 70px;
        width: calc(100% - 40px) !important;
        padding: 0 20px;
    }

    .header-reverse {
        height: 70px;
        width: calc(100% - 40px) !important;
        padding: 0 20px;
    }

    .configurations .configuration .drop-down.expanded ul {
        padding-left: 0;
    }

    .cart-wrapper {
        order: 2;
        margin-left: 20px;
    }

    .main-page-footer {
        display: none;
    }

    .main-wrapper {
        padding-top: 64px;
        padding-bottom: 58px;

        .main-content-wrapper {
            .configurations .configuration > a {
                display: none;
            }

            .search,
            .location,
            .meal-delivery,
            .customer,
            .user,
            .item {
                display: none;
            }
        }
    }

    .main-wrapper-reverse {
        padding-top: 64px;
        padding-bottom: 58px;

        .main-content-wrapper {
            .configurations .configuration > a {
                display: none;
            }

            .search,
            .location,
            .meal-delivery,
            .customer,
            .user,
            .item {
                display: none;
            }
        }
    }

    .list-calendars-tag {
        height: 25px;
        margin-right: 5px;
    }

    .list-item {
        display: flex;
        align-items: center;
        border-top: 1px solid #eeeeee;
        border-right: 9px solid transparent;
        padding-right: 30px;
    }


    .list-item.reverse {
        flex-direction: row-reverse;
    }

    .drop-down {
        position: absolute;
        width: 100%;
    }

    .drop-down.expanded {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
        position: absolute;
        z-index: 1051;
        border-radius: 0;
        width: 100%;
    }
}

.expanded-parent {
    height: 100%;
}

.desktop-only {
    display: none;
}

.display-amount-block {
    background: #FFFFFF;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index: 10;
    display: flex;
}

.display-amount-block__item {
    width: 100%;
    padding: 16px 0;
    border: 2px solid #DEDEDE;
    border-right: 0;
    border-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
    color: #3E4954;

    &:last-child {
        border-left: 0;
    }
}

.display-amount-block__item-amount {
    font-size: 16px;
    line-height: 12px;
    margin-right: 8px;
    color: #BC1E46;
}

.list {
    .list-inner-icon {
        max-width: 20px;
        margin-left: 10px;
        border-radius: 50%;
        height: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .main-page-content-wrapper {
        height: fit-content;
        min-height: 100vh;
        position: relative;
    }

    //----------------------------------------------------------
    .confirmation-window__close-icon {
        top: 24px;
        left: 24px;
    }

    .main-page-content-wrapper .modal-content {
        width: calc((651 / 1500 * 100% - (14 / 375 * 100% * 2)) - (12px * 2));
    }
    //----------------------------------------------------------

    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: flex;
    }

    .order-countdown-reverse {
        width: fit-content;
        border-radius: 5px;
        padding-right: 10px;
        margin: 21px auto 21px 0;

        > span {
            padding-right: 20px;
        }
    }

    .order-countdown {
        width: fit-content;
        border-radius: 5px;
        padding-left: 10px;
        margin: 21px 0 21px auto;

        > span {
            padding-left: 20px;
        }
    }

    .order-countdown.order-countdown-store {
        margin-right: 2rem;
    }

    .list-calendars-tag:last-child {
        margin-right: 25px;
    }

    .main-order-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;

        .order-item-info-wrapper {
            border-radius: 20px;
            max-height: calc(100% - 120px);
            width: calc(100% - 70px);
        }
    }


    .main-wrapper-reverse {
        display: flex;
        justify-content: space-between;
        padding: 50px 36px 60px 36px;
        flex-direction: row-reverse;
        text-align: left;


        .main-content-wrapper {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            z-index: 150;
            width: 100%;

            .configurations .configuration > a {
                display: block;
                text-align: left;
            }

            .search,
            .location,
            .meal-delivery,
            .customer,
            .user,
            .budget,
            .item {
                display: flex;
                flex-direction: column;
                box-shadow: none;
                border-radius: 0;
                flex-direction: row-reverse;
            }

            .configurations {
                flex-direction: row;
                margin-left: 40px;
                margin-right: auto;
                width: auto;
                display: flex;
            }

            .configuration {
                margin: 0 10px 0 10px;
                width: 280px;
                height: 50px;
                position: relative;
            }
        }

        .configurations {
            margin-top: 50px;
            width: 280px;
        }
    }

    .main-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 50px 80px 60px 36px;

        .main-content-wrapper {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            z-index: 150;
            width: 100%;

            .configurations .configuration > a {
                display: block;
                text-align: right;
            }

            .search,
            .location,
            .meal-delivery,
            .customer,
            .user,
            .budget,
            .item {
                display: flex;
                flex-direction: column;
                box-shadow: none;
                border-radius: 0;
            }

            .configurations {
                flex-direction: row-reverse;
                margin-left: auto;
                margin-right: 40px;
                width: auto;
                display: flex;
            }

            .configuration {
                margin: 0 10px 0 10px;
                width: 280px;
                height: 50px;
                position: relative;
            }
        }

        .configurations {
            margin-top: 50px;
            width: 280px;
        }
    }

    .drop-down.expanded {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
        position: absolute;
        width: 100%;
    }

    .dishes-list-title-wrapper {
        margin-right: 50px;
    }

    .dishes-list-title-wrapper-reverse {
        margin-left: 50px;
    }

    .dishes-list-title {
        display: block;
        font-size: 42px;
        font-weight: bold;
        text-align: right;
        color: #bc1e46;

        > span {
            font-size: 22.5px;
            font-weight: normal;
        }
    }
    .dishes-list-title-reverse {
        display: block;
        font-size: 42px;
        font-weight: bold;
        text-align: left;
        color: #bc1e46;

        > span {
            font-size: 22.5px;
            font-weight: normal;
        }
    }

    .ordered-item-row {
        display: flex;
        flex-wrap: wrap;
    }

    .ordered-item-block {
        margin-right: 0;
        width: min-content;
        min-height: 70px;

        .ordered-item-block-info {
            width: calc(100% - 70px - 106px);
            min-width: 150px;
        }

        .ordered-item-block-status {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #bc1e46;
            margin: auto 10px;
        }
    }

    .dishes-list-wrapper-reverse {
        display: flex;
        flex-wrap: wrap;
        padding-top: 44px;
        align-content: flex-start;
        position: relative;
        flex-direction: row-reverse;

        .loading-animation-wrapper {
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(-50%);
        }
    }

    .dishes-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 44px;
        align-content: flex-start;
        position: relative;

        .loading-animation-wrapper {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .dish {
        margin: 10px 20/1160 * 100%;
        width: 346/1160 * 100%;
    }

    .dish-information {
        padding: 0px 25px;
    }

    .configurations > div {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    }

    .configurations .configuration {
        padding: 0;
        margin: 10px 0;
        display: none;
    }

    .configurations .configuration > a {
        padding-left: 30px;
        display: none;
    }

    .configurations .configuration ul {
        display: block;
    }

    .drop-down ul {
        display: none;
    }

    .drop-down.expanded ul {
        display: block;
    }

    .search,
    .location,
    .meal-delivery,
    .customer,
    .user,
    .budget,
    .item {
        display: none;
    }

    .list {
        .list-title {
            display: block;
            // width: calc(100% - 65px);
            text-align: right;
            padding-right: 25px;
            font-size: 16px;
            font-weight: bold;
            line-height: 48px;
            color: #cacfd9;
            position: relative;
            direction: rtl;
        }
    }

    .list-title.reverse {
        display: block;
        text-align: left !important;
        padding-right: 0px !important;
        padding-left: 70px !important;
        direction: ltr !important;
    }

    .list .list-title:before {
        content: "";
        position: absolute;
        width: 28px;
        height: 23px;
        margin: auto 0;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        margin-left: 10px;
    }
    .list .reverse.list-title:before {
        top: 50%;
        right: auto !important;
        left: 25px !important;
        margin-left: 0px !important;
        margin-right: 10px !important;
    }

    .list.calendars, .list.types, .list.tags, .list.suppliers, .list.meal-type, .list.meal-delivery {
        .list-title {
            padding-right: 65px;
        }
    }

    .list.calendars .list-title:before {
        background: url("../../images/calendar_icon.svg") no-repeat;
    }

    .list.types .list-title:before {
        background: url("../../images/types_icon.svg") no-repeat;
    }

    .list.tags .list-title:before {
        background: url("../../images/meal_icon.svg") no-repeat;
    }

    .list.suppliers .list-title:before {
        background: url("../../images/meal_icon.svg") no-repeat;
    }

    .list.meal-type .list-title:before {
        background: url("../../images/meal_icon.svg") no-repeat;
    }

    .list.meal-delivery .list-title:before {
        background: url("../../images/delivery_icon.svg") no-repeat;
    }

    .configurations .list {
        display: block;
        margin-bottom: 20px;

        .list-item {
            border-right: 9px solid transparent;
            margin: 0;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            justify-content: flex-start;
        }

        .list-item.active svg {
            opacity: 1;
        }

        .checkbox {
            display: flex;
        }

        .list-item:not(:last-child) {
            border-bottom: 1px solid #eeeeee;
        }

        .list-calendars-tags-wrapper {
            display: block;
            border-bottom: 1px solid #eeeeee;

            .list-item {
                border-bottom: none;
            }
        }

        .list-item.active {
            background-color: #ffffff !important;
            color: #3e4954;
            border-right-color: #bc1e46;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            background-image: linear-gradient( to left, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) );
        }
    }

    .list {
        .list-item.display-amount-block__item {
            display: flex;
            font-size: 18px;
            line-height: 20px;
            padding: 20px 24px;
            border: 0;
            border-bottom: 1;

            &:last-child {
                border-bottom: 0;
            }
        }

        .display-amount-block__item-amount {
            font-size: 18px;
            line-height: 20px;
            margin-left: 0 !important;
            margin-right: auto;
        }
    }
}

.main-page-content-wrapper .filter-panel {
    background: #fafafa;

    .filter-panel__header {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 15px 18px;
        background-color: #f7f9fc;
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #3e4954;
    }

    .filter-panel__header__title {
        margin-right: 50%;
        transform: translateX(50%);
    }

    .filter-panel__footer {
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        position: fixed;
        width: -webkit-fill-available;
        bottom: 0;
        margin: 10px 0 0;
        padding: 10px 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filter-panel__footer__confirm {
        width: 20%;
        height: 45px;
        object-fit: contain;
        border-radius: 6px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: normal;
        background-color: #bc1e46;
        width: 50%;
    }

    .filter-panel__footer__available {
        margin-left: auto;
        margin-right: auto;
    }

    .filter-panel__info {
        min-height: calc(100vh - 50px);

        .list {
            display: block;
            margin-bottom: 18px;
            padding-bottom: 4rem;

            .list-item {
                border-radius: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }

            .list-item.active label {
                svg {
                    opacity: 1;
                }
            }

            .checkbox {
                display: flex;
            }

            .list-title {
                display: block;
                // width: calc(100% - 65px);
                text-align: right;
                padding-right: 65px;
                font-size: 16px;
                font-weight: bold;
                line-height: 48px;
                color: #cacfd9;
                position: relative;
                background-color: #ffffff;
            }

            .list-inner-icon {
                width: 20px;
                margin-left: 10px;
                border-radius: 50%;
                height: 20px;
            }

            .active {
                color: #3e4954;
                border-right-color: #bc1e46;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                background: linear-gradient( to left, rgba(188, 30, 70, 0.24), rgba(188, 30, 70, 0) );
            }
        }

        .list .list-title:before {
            content: "";
            position: absolute;
            width: 28px;
            height: 23px;
            margin: auto 0;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            margin-left: 10px;
        }

        .list.types .list-title:before {
            background: url("../../images/types_icon.svg") no-repeat;
        }

        .list.tags .list-title:before {
            background: url("../../images/meal_icon.svg") no-repeat;
        }

        .list.tags::-webkit-scrollbar {
            display: none;
        }

        .list.suppliers .list-title:before {
            background: url("../../images/meal_icon.svg") no-repeat;
        }

        .list.suppliers::-webkit-scrollbar {
            display: none;
        }

        .list.meal-type .list-title:before {
            background: url("../../images/meal_icon.svg") no-repeat;
        }

        .list.meal-delivery .list-title:before {
            background: url("../../images/delivery_icon.svg") no-repeat;
        }

        .configurations .list {
            display: block;
            margin-bottom: 20px;

            .list-item {
                border-right: 9px solid transparent;
                margin: 0;
                border-radius: 0;
                box-shadow: none;
                display: flex;
                justify-content: flex-end;
                border-radius: 0;
            }

            .list-item.active svg {
                opacity: 1;
            }

            .list-item:not(:last-child) {
                border-bottom: 1px solid #eeeeee;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .price-item {
        direction: rtl;
        display: flex;
        overflow-y: hidden;
        justify-content: initial;
        flex-wrap: inherit;
    }

    .price-item::-webkit-scrollbar {
        display: none;
    }
}

.dish-image-filler-full {
    width: 100%;
}

.ordered-item-block-delivery-time {
    font-size: 12px;
    color: #666f80;
}

.ordered-item-block-delivery-time-title {
    margin-left: 0.25rem;
}

.ordered-item-block-delivery-time-title-en {
     margin-right: 0.25rem;
}

.ordered-item-block-price_info-title {
    margin-left: 10rem;
    display: contents;
}


.configuration-meal-delivery-time {
    .configuration {
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;

        .drop-down {
            width: calc(100% - 2rem);
            border: 1px solid #eaeaea;
            margin: auto;
            border-radius: 6px;
        }
    }
}

.drop-down-container-title {
    display: flex;
    flex-direction: column;

    .subtitle {
        font-size: 0.75rem;
        background-blend-mode: darken;
    }

    .title {
        color: #742232
    }
}
