$dark-red: #6e1427;
$red: #bc1e46;

.dropdown-container {

  .modal-overlay {
    z-index: 1000;
  }

  .input-field {
    label {
      font-size: 14px;
      letter-spacing: normal;
      text-align: right;
      color: $dark-red;
      margin: 10px 20px 10px 0;
    }

    input,
    textarea {
      width: calc(100% - 49px);
      text-align: right;
      background-position: right 10px center !important;
      background-repeat: no-repeat !important;
      padding-right: 45px;

      &::placeholder {
        font-size: 14px;
        text-align: right;
        color: #b4bbca;
      }
    }

    textarea {
      border-radius: 26px;
      background-position: right 10px top 9px !important;
      border: solid 1px #e3e3e3;
      min-height: 115px;
      color: #666f80;
      padding: 9px 45px 9px 10px;
      width: calc(100% - 49px - 10px);
      font-family: "OpenSansHebrew";

      overflow: auto;
      outline: none;
      box-shadow: none;

      resize: none;

      font-size: 14px;
      line-height: 1.43;
    }
  }

  max-width: 150px;
  position: relative;

  .drop-down-wrapper {
    position: relative;
    height: 40px;
  }

  .drop-down {
    width: 100%;
    background-color: #ffffff;
    transition: 0.5s;
    z-index: 1051;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 5%;
      top: 14px;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition-duration: 0.1s;
    }
  }

  .drop-down-contents {
    transform-origin: top;
    transform: scaleY(0);
    transition: 0.5s;
    max-height: 0;
    border-radius: 100px;
  }

  .drop-down-option {
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
    background-color: #ffffff;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .active {
    background-color: #f1f1f1;
  }

  .drop-down.expanded {
    position: absolute;
    width: 100%;
    min-width: 150px;
    transition: 0.5s;
    border-radius: 19px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
    overflow: hidden;

    &:before {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition-duration: 0.1s;
    }

    .drop-down-contents {
      transform: scaleY(1);
      transition: 0.5s;
      max-height: initial;
    }
  }

  .drop-down-wrapper {
    min-width: 100px;
  }

  .input-selector {
    min-width: 150px;
    cursor: pointer;
  }

  @media screen and (min-width: 1024px) {

    .confirmation-window-tag {
      margin-top: 30px;

      > img {
        height: 90px;
        width: 90px;
      }
    }

    .confirmation-window-primary-text {
      font-size: 42px;
      font-weight: bold;
    }

    .confirmation-window-secondary-text {
      div {
        margin: 0;
      }

      > div {
        margin: 20px 0;
      }

      .horizontal-divider {
        margin: 0 5px;
        user-select: none;
      }
    }

    .subject-field {
      width: 100%;
    }

    .form-title {
      margin-top: 30px;
    }

    .confirmation-window-footer {
      justify-content: flex-end;
    }

    .email-phone-wrapper {
      display: flex;
      align-items: center;
    }

    .button {
      width: auto;
    }

    .dialog {
      margin-left: 0;
      margin-right: auto;
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;

    }
  }

}
