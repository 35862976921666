.cards-drop-down-wrapper {
  width: 100%;
  color: #6E1427;

  input {
    border-radius: 4px;
    color: #6E1427;
    text-align: right;
    padding: 5px 40px;
    background-color: #ffffff;
  }

  .drop-down-wrapper {
    position: relative;
    height: 40px;
    text-align: right;
  }

  .drop-down {
    width: 100%;
    background-color: #ffffff;
    transition: 0.5s;
    z-index: 1;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 4%;
      top: 16px;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition-duration: 0.1s;
    }

    &:after {
      content: "";
      width: 11px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 16px;
      background: url("../../images/checkbox_mark.svg") no-repeat;
    }
  }

  .drop-down-contents {
    transform-origin: top;
    transform: scaleY(0);
    transition: 0.5s;
    max-height: 0;
    border-radius: 4px;
  }

  .drop-down-option {
    border-bottom: 1px solid #eee;
    padding: 5px 40px;
    background-color: #ffffff;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .drop-down.expanded {
    position: absolute;
    width: 100%;
    z-index: 10;

    transition: 0.5s;
    border-radius: 4px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
    overflow: hidden;

    &:before {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition-duration: 0.1s;
    }

    .drop-down-contents {
      transform: scaleY(1);
      transition: 0.5s;
      max-height: 105px;
      overflow-y: auto;
    }
  }
  .input-pointer {
      cursor: pointer;
  }
}
