@mixin form-label() {
  text-align: right;
  color: #6e1427;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.refunded-item {
  color: #bc1e46;
}

.amount-sum {
  direction: ltr;
}

.account-page-wrapper {

  h1 {
    line-height: 36px;
    padding: 16px 0;
    margin: 0;
    text-align: center;
    color: #bc1e46;
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .menu-nav {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    .menu-item {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      position: relative;
      width: 90%;
      min-height: 72px;
      border-radius: 6px;
      border: solid 1px #d3d3d3;
      padding: 20px;
      margin-bottom: 8px;
      color: #6e1427;

      &:active {
        background-color: #FCF6F8;
      }
    }

    .menu-item__icon {
      width: 30px;
      margin-left: 20px;
    }

    .menu-item.active {
      background-color: #fcf6f8;
    }
  }
}

.profile-page-wrapper {
  direction: rtl;
  padding-top: 5px;

  .main-wrapper {
    padding-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
    position: relative;

    .top-row {
      padding-top: 40px;
      padding-right: 80px;
      padding-bottom: 40px;
      display: flex;
    }

    .back-link {
      width: 280px;
      display: flex;
      align-items: center;
    }

    .desktop-title {
      font-size: 42px;
      font-weight: bold;
      text-align: right;
      color: #bc1e46;
    }

    .main-wrapper {
      padding-top: 0;
      padding-left: 150/1600 * 100%;
      padding-bottom: 85px;

      .main-content-wrapper {
        padding-top: 0;
      }

      .configurations {
        margin-top: 0;
      }
    }
  }
}

.profile-content-wrapper {
  padding: 20px 20px;

  h1 {
    line-height: 42px;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #bc1e46;
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .divider {
    border-bottom: 1px solid #dedede;
  }

  .card {
    position: relative;
    margin: 5px 0;
    padding: 15px 20px;
    height: 180px;
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  .card-number {
    padding: 20px 0;
    text-align: right;
    border-bottom: 1px solid #e3e3e3;
  }

  .card-expiration {
    padding: 10px 0;
    text-align: right;
  }

  .card-number .parameter:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0px;
    width: 18px;
    height: 13.5px;
    background-image: url("../../images/card_icon.svg");
  }

  .card-expiration .parameter:after {
    content: "";
    position: absolute;
    top: 3px;
    right: 0px;
    width: 19px;
    height: 19px;
    background-image: url("../../images/calendar_icon.svg");
    background-size: contain;
  }

  .card .value {
    position: relative;
    color: #3e4954;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .card .parameter {
    position: relative;
    padding: 0 30px 0 10px;
    color: #6e1427;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .card .card-remove-button {
    display: block;
    padding-right: 21px;
    position: relative;
    bottom: 0px;    
    text-align: center;
    width: 85px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #bc1e46;
    border-radius: 10px;
    margin-left: 0;
    margin-right: auto;
    user-select: none;
    cursor: pointer;
  }

  .card .card-remove-button:after {
    content: "";
    position: absolute;
    top: 14px;
    right: 20px;
    width: 12.1px;
    height: 13px;
    background-image: url("../../images/trash_icon.svg");
  }

  .content-wrapper {
    padding: 25px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
  }

  .content-wrapper .forms-section {
    padding: 10px 0;
  }

  .alerts-form-section-text {
    padding-top: 26px;
  }

  .alerts-form-section-text__header {
    @include form-label();
  }

  .alerts-form-section-text__helper-text {
    font-size: 14px;
    line-height: 20px;
    color: #3E4954;
  }

  .form {
    margin-bottom: 20px;
  }

  .form label {
    @include form-label();
  }

  .form input {
    text-align: right;
    background-position: right 10px center !important;
    background-repeat: no-repeat !important;
    padding-right: 45px;
  }

  .form-switch {
    display: flex;
    align-items: center;

    padding: 16px 0;

    font-size: 14px;
    line-height: 20px;
    color: #3E4954;
    border-bottom: 1px solid #dedede;
  }

  .form-switch__switch {
    margin-left: 0;
    margin-right: auto;
  }

  .forms-save-button {
    display: block;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 10px;
    background-color: #bc1e46;
    color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    user-select: none;
    cursor: pointer;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 40px 0 0;

    h1 {
      display: block;
      text-align: right;
      color: #565152;
      margin-bottom: 20px;
    }

    h1.mobile-only {
      display: none;
    }

    .divider {
      margin-left: 36/605 * 100%;
    }

    .forms-wrapper {
      margin: 0 25/1045 * 100%;
    }

    .forms-section {
      display: flex;
      flex-wrap: wrap;
    }

    .form {
      width: 33%;
      margin-bottom: 22px;
    }

    .form label {
      margin-bottom: 10px;
    }

    .form input {
      width: calc(100% - 45px);
    }

    .forms-save-button {
      width: fit-content;
      padding: 0 50px;
      margin-left: 0;
      margin-right: auto;
    }

    .form-switch {
      width: calc(33% - 45px);
      margin-bottom: 22px;
      margin-left: 45px;
    }

    .cards-wrapper {
      padding: 32px;
      box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    }

    .card {
      display: flex;
      align-items: center;
      height: auto;

      .card-number {
        padding: 0;
        border: 0;

        &:after {
          content: "";
          border-right: 1px solid #dedede;
          padding: 10px 0;
          margin: 0 10px;
        }
      }

      .card-remove-button {
        bottom: 0;
      }
    }
  }
}

.wallet-btn {
  border: 1px solid #BC1E46;
  padding: 0.5rem;
  box-sizing: border-box;
  color: #BC1E46;
  filter: drop-shadow(0px 4px 30px rgba(110, 20, 39, 0.12));
  border-radius: 10px;
  max-width: 200px;
  display: inline-block;
  cursor: pointer;
  img {
    margin-left: 0.5rem;
  }
}

.confirm-transfer-button-desctop {
  margin-top: 0.25rem;
  display: block;
  height: 64px;
  line-height: 54px;
  text-align: center;
  background-color: #bc1e46;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  border-radius: 10px;
}

.balance-description, .balance-title {
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
}

.balance-description {
  color: #3E4954;
  .amount {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-weight: bold;
  }
}

.balance-form__amount-input {
  text-align: right;
  background: #FFFFFF;
  border: 1px solid #6E1427;
  border-radius: 6px;
  padding: 0 20px;

  &::placeholder, &::-webkit-input-placeholder {
    text-align: right;
  }
}

.balance-title {
  color: #6E1427;
}

.attention-container {
  font-weight: bold;
  color: #bc1e46;
  border-bottom: 1px solid lightgrey;
}

.add-card-button {
    display: block;
    margin: 10px 0;
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #bc1e46;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #bc1e46;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.add-card-button:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16px;
    right: 16px;
    background-image: url("../../images/card.svg");
}


.modal-content {
    width: calc((100% - (14 / 375 * 100% * 2)) - (12px * 2)) !important;
}
.tranzila-wrapper {
    height: 400px;
}

@media screen and (min-width: 1024px) {

    .modal-content {
        width: calc((651 / 1500 * 100% - (14 / 375 * 100% * 2)) - (12px * 2)) !important;
    }
}

