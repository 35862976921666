.payment-selection-form {
  padding-top: 12px;
  border-top: 1px solid #DBDBDB;
  
  .payment-selection-form__option-selector {
    display: flex;
    justify-content: space-between;
  }

  .payment-selection-form__option {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 6px;

    width: calc(50% - 5px);
    height: 44px;
  }
  .payment-selection-form__option__budget {
    display: flex;
    align-items: center;
    padding: 10px 6px;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 6px;

    width: calc(100%);
    height: 44px;
  }

  .payment-selection-form__option-text {
    margin: 0 10px;
    color: #3E4954;
  }
  .payment-selection-form__amount-input-wrapper {
    padding-top: 12px;
  }
  .payment-selection-form__amount-input {
    text-align: right;
    background: #FFFFFF;
    border: 1px solid #6E1427;
    border-radius: 6px;
    padding: 0 20px;

    &::placeholder, &::-webkit-input-placeholder {
      text-align: right;
    }
  }

  .MuiRadio-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #6E1427;
  }
}
