$dark-red: #6e1427;
$dark: #3e4954;
$purplish-brown: #565152;
$red: #bc1e46;

%header-text {
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: normal;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

%label-text {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: right;
    color: $purplish-brown;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

%order-row-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

// Quantity-counter
//----------------------------------------------------------
.quantity-counter {
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.6px 10.3px 2.6px 7.8px;
    border-radius: 71px;
    border: solid 1px #e0e0e0;
    background-color: #f1f1f1;
    margin-right: auto;
    margin-left: 0;

    div {
        width: 33.3333333333%;
        display: flex;
        justify-content: center;
    }
}

.--error {
    border: solid 1px #e24047 !important;
}

.input-quantity {
    display: initial;
    border-radius: 0;
    width: 100%;
    height: auto;
    font-size: 18.1px;
    text-align: center;
    color: $dark;
    padding: 0;
}
//----------------------------------------------------------

// Tags
//----------------------------------------------------------
.tag-wrapper {
    display: flex;
    align-items: center;

    .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        border-radius: 30px;
        background-color: #f5f5f5;
        margin: 3/353 * 100%;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: $dark-red;

        img {
            height: 16px;
            width: 16px;
            margin-left: 8px;
        }
    }
}
//----------------------------------------------------------

.order-item-info-wrapper {
    .modal-content {
        width: calc((100% - (14 / 375 * 100% * 2)) - (12px * 2));
    }
    //----------------------------------------------------------
    .confirmation-window-close-icon {
        position: absolute;
        top: 12px;
        left: 12px;
    }

    .confirmation-window-primary-text {
        margin: 0;
        font-size: 25.6px;
        font-weight: bold;
        line-height: 1.95;
        text-align: center;
        color: $red;

        .main-wrapper-reverse & {
            direction: ltr;
        }
    }

    .confirmation-window-secondary-text {
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #666f80;
    }

    .confirmation-window-footer {
        margin-top: 14px;
    }

    .confirmation-window-footer button {
        width: calc((50%) - 8px);
        min-height: 47px;
        border-radius: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }
    //----------------------------------------------------------

    .info-line {
        @extend %header-text;
        padding: 12px 22px;
        border-bottom: 1px solid #dedede;
    }

    .img-partner {
        margin-right: 16px;
        margin-left: auto;

        .option-select-text {
            color: #666f80;
            font-size: 16px;
        }

        .main-wrapper-reverse & {
            margin-right: auto;
            margin-left: 16px;
        }
    }

    .meal-type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 8px;
        border-radius: 30px;
        border: solid 1px $dark-red;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: normal;
        color: $dark-red;

        img {
            margin-left: 8px;
        }
    }

    .meal-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .meal-img-filler {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .document-button {
        position: absolute;
        background-color: white;
        margin: 7px auto auto 7px;
        padding: 10px 9px;
        border-radius: 3px;
        box-shadow: 0 4px 30px 0 white;
        border: solid 1px $dark-red;
        font-size: 14px;
        line-height: 2.14;
        letter-spacing: normal;
        color: $dark-red;
    }

    .meal-name, .meal-name-small {
        font-size: 25.6px;
        font-weight: bold;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: center;
        color: $dark-red;
    }

    .meal-name-small {
        font-size: 20.6px;
    }

    .meal-description, .meal-description-small {
        margin-left: auto;
        margin-right: 24/375 * 100%;
        width: 309/375 * 100%;
        font-size: 14px;
        text-align: right;
        color: #666f80;
    }

    .meal-description-small {
        font-size: 12px;
    }

    .item-photo-wrapper {
        position: relative;
        min-height: 120px;
        padding: 0px 20px;
    }

    .certificate-btn-wrapper {
        width: fit-content;
        height: fit-content;
        padding: 2px;
        background-color: #ffffff;
        border-radius: 4px;
        margin-right: auto;
        margin-left: 0;
        order: 1;
        flex-shrink: 0;
        position: absolute;
        left: -21/343 * 100%;
        top: -14px;

        > a {
            width: 100%;
            height: 100%;
        }
    }

    .certificate-btn {
        box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
        border: solid 1px $dark-red;
        background-color: #ffffff;
        padding: 4px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        color: $dark-red;
        user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    .certificate-btn__icon {
        margin-left: 4px;
    }

    .image-elements-wrapper {
        position: absolute;
        left: 21/343 * 100%;
        right: 21/343 * 100%;
        top: 17px;
        display: flex;
    }

    .image-tags-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .image-tag {
        margin: 0 0 6px 9px;
        padding: 2px 14px;
        border-radius: 30px;
        background-color: #ffffff;
        font-size: 12px;
        line-height: 1.67;
        text-align: center;
        color: $dark-red;
    }

    .content-wrapper {
        margin: 0 11/375 * 100%;
        position: relative;
    }

    .tag-wrapper {
        padding: 14px 0;
    }

    .supplier-line {
        @extend %header-text;
        padding: 10px 5/353 * 100% 10px 0;
    }

    .meal-supplier {
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: normal;
        margin-left: auto;
        margin-right: 20px;
        color: #666f80;

        .main-wrapper-reverse & {
            margin-left: 20px;
            margin-right: auto;
        }
    }

    .divider {
        border-bottom: 1px solid #dedede;
    }

    .option-group__label {
        @extend %label-text;
        padding: 0 5/353 * 100% 24px;
    }

    .option-group__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .option-group {
        padding: 26px 0;
    }

    .option-group--error {
        background-color: #e6c5cd;

        .option-group__label {
            color: #ffffff;
        }
    }

    .option-wrapper {
        min-width: calc(33.3333333333333% - (6 / 353 * 100% * 2) - 8px);
        margin: 4px 6/353 * 100%;
    }

    .option {
        padding: 8px;
        border-radius: 100px;
        box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
        border: solid 1px #eaeaea;
        background-color: #ffffff;
        font-size: 14px;
        line-height: 2.14;
        text-align: center;
        color: $dark-red;

        img {
            display: none;
        }
    }

    .option__text {
        padding: 0 16px;
    }

    .option--selected {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        background-color: $red;
        position: relative;

        .option__text {
            padding: 0;
            width: calc(100% - 24px);
        }

        img {
            display: initial;
            margin-left: 0.5rem;
        }
    }

    .order-row {
        position: sticky;
        bottom: 0;
        margin: 10px 0 0;
    }

    .order-row__cancel-button {
        @extend %order-row-button;
        font-weight: normal;
        color: $dark;
        display: none;

        .img-partner {
            margin: 0;
        }
    }

    .order-row__cancel-button__close-icon {
        margin-right: auto;
        margin-left: 8px;
    }

    .order-row__order {
        @extend %order-row-button;
        padding: 13px 42/375 * 100% 13px 16/375 * 100%;
        background-color: $red;

        .img-partner {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .cancel-header {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 17px 22px 12px 22px;
        background: white;
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: content-box;
    }

    .note__label {
        @extend %label-text;
        padding: 12px 0 16px;
    }

    textarea {
        border-radius: 4px;
        border: solid 1px #969ba0;
        min-height: 110px;
        font-size: 16px;
        line-height: 1.88;
        color: #666f80;
        padding: 6px 14px;
        width: calc((100%) - 26px);
        font-family: "OpenSansHebrew";
        text-align: right;
        resize: none;

        .main-wrapper-reverse & {
            text-align: left;
        }
    }

    textarea::placeholder {
        font-size: 16px;
        line-height: 1.88;
        text-align: right;
        color: #b4bbca;

        .main-wrapper-reverse & {
            text-align: left;
        }
    }

    .order-row__summary-price {
        width: 75/375 * 100%;
        height: 40px;
        object-fit: contain;
        border-radius: 2px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: $red;
    }

    .error-window {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        left: 17/375 * 100%;
        right: 17/375 * 100%;
        z-index: 1;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: $dark-red;
    }

    .error-tag {
        margin-bottom: 7px;

        img {
            width: 52px;
        }
    }

    @media screen and (min-width: 1024px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 0 0;
        background-color: #fafafa;
        overflow-y: auto;

        .modal-content {
            width: calc((651 / 1500 * 100% - (14 / 375 * 100% * 2)) - (12px * 2));
        }

        .confirmation-window__close-icon {
            top: 24px;
            left: 24px;
        }

        .info-line {
            display: none;
        }

        .item-photo-wrapper {
            width: 444/1478 * 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 0px 20px;
        }

        .certificate-btn-wrapper {
            border-radius: 10px;
            position: initial;
            margin-right: 0;
            margin-left: auto;
            order: 0;
        }

        .certificate-btn {
            padding: 8px;
            border-radius: 10px;
        }

        .image-elements-wrapper {
            display: block;
        }

        .image-tags-wrapper {
            margin-top: 10px;
        }

        .meal-img {
            border-radius: 20px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .meal-img-filler {
            width: 120/360 * 100%;
        }

        .item-info-wrapper {
            width: 964/1478 * 100%;
            margin-left: 19/1478 * 100%;
        }

        .content-wrapper {
            position: relative;
            margin: 0;
            padding: 30px 24/651 * 100%;
            box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
            background-color: #ffffff;
            border-radius: 10px;
        }

        .meal-name, .meal-name-small {
            margin-left: auto;
            margin-right: 8/599 * 100%;
            text-align: right;
            font-size: 30px;

            .main-wrapper-reverse & {
                margin-left: 8/599 * 100%;
                margin-right: auto;
                text-align: left;
            }
        }

        .meal-name-small {
            font-size: 20px;
        }

        .meal-description, .meal-description-small {
            margin-right: 8/599 * 100%;
            font-size: 18px;

            .main-wrapper-reverse & {
                margin-left: 8/599 * 100%;
                margin-right: auto;
                text-align: left;
            }
        }

        .meal-description-small {
            font-size: 14px;
        }

        .option-groups-wrapper {
            overflow: auto;
        }

        .option-wrapper {
            min-width: auto;
            margin: 8px;
            user-select: none;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
        }

        .option {
            padding: 8px 35px;
        }

        .option__text {
            padding: 0;
        }

        .option--selected {
            .option__text {
                padding: 0;
                width: auto;
                transform: translateX(-10px);
            }

            img {
                position: absolute;
                right: 14px;
            }
        }

        .option-group {
            padding: 4px 0;
        }

        .option-group__label {
            font-size: 18px;
            padding-bottom: 2px;
        }

        .note__label {
            font-size: 18px;
        }

        textarea {
            min-height: auto;
        }

        .order-row {
            display: flex;
            position: initial;
            margin: 14px 0 0;
            padding-bottom: 30px;
        }

        .order-row__order {
            width: 410/651 * 100%;
            padding: 12px 20px;
            border-radius: 10px;
            box-shadow: 0 10px 20px 0 rgba(255, 102, 51, 0.01);
            border: solid 1px $red;
        }

        .order-row__cancel-button {
            display: flex;
            padding: 12px 20px;
            border-radius: 10px;

            .img-partner {
                margin-right: auto;
                margin-left: auto;
            }

            box-shadow: 0 10px 20px 0 rgba(255, 102, 51, 0.01);
            border: solid 1px $dark;
            background-color: #ffffff;
            width: 231/651 * 100%;
            margin-left: calc(14 / 651 * 100%);
            margin-right: auto;

            .main-wrapper-reverse & {
                margin-right: 14/651 * 100%;
                ;
                margin-left: auto;
            }
        }

        .order-row__cancel-button__close-icon {
            margin: 0;
        }

        .cancel-header {
            display: none;
        }

        .error-window {
            display: flex;
            align-items: center;
            position: absolute;
            top: 12px;
            transform: initial;
            padding: 12px 21px;
        }

        .info-row {
            margin-left: auto;
            margin-right: auto;
        }

        .error-tag {
            margin: 0;

            img {
                width: 35px;
            }
        }
    }
}
