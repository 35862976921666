$red: #bc1e46;

.faq-page-wrapper {
  padding-top: 70px;
  padding-bottom: 58px;

  .title {
    font-size: 22px;
    font-weight: bold;
    color: $red;
  }

  .top-row {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .back-link {
    margin-right: 8%;
    display: flex;

    > span,
    > img {
      user-select: none;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .title {
    margin-right: auto;
    margin-left: auto;

    display: flex;
    align-items: center;
  }

  .title__icon {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }

  @media screen and (min-width: 1024px) {
    padding: 65px 125/1600 * 100% 85px 150/1600 * 100%;

    .top-row {
      display: block;
    }

    .back-link {
      margin: 20px 0;
    }

    .title {
      font-size: 34px;
      margin-bottom: 13px;
    }

    .title__icon {
      width: 32px;
      height: 32px;
    }
  }
}
