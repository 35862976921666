$red: #bc1e46;

.terms-page-wrapper {
  padding-top: 70px;
  padding-bottom: 58px;

  .title {
    font-size: 22px;
    font-weight: bold;
    color: $red;
  }

  @media screen and (min-width: 1024px) {
    padding: 165px 125/1600 * 100% 85px 150/1600 * 100%;

    .title {
      font-size: 34px;
      margin-bottom: 13px;
    }
    .main-page-footer {
      position: relative;
    }
  }
}
