.meal-container {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin: 0;
  padding: 30px 3.68664%;
  box-shadow: 0 4px 30px 0 rgba(110,20,39,.12);
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 80vh;
}


.meal-side-info-image-container {
  max-width: 300px;
}


.meal-side-info-container {
  width: 100%;
}

.item-info-wrapper.item-info-wrapper-combo {
  width: 90%;
  height: 100%;
  .meal-container {
    height: 100%;
    justify-content: flex-end;
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {
  .tag-wrapper-container {
    width: 100%;
  }
  .meal-tags-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .item-info-wrapper.item-info-wrapper-combo {
    width: 100%;
    .meal-container {
      flex-direction: column-reverse;
    }
  }
  .order-item-info-wrapper .meal-img-filler {
    display: none;
  }
  .meal-container {
    max-height: calc(100vh - 6.25rem);
  }
  .meal-side-info-image-container {
    width: 100%;
    margin: auto;
    max-width: initial;
  }
}

.content-wrapper.content-wrapper-combo {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.meal-img-filler {
  width: 120/360 * 100%;
  min-width: 150px;
  position: relative!important;
}

.main-combo-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
}
