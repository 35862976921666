.disabled-link {
    pointer-events: none;
}

.page-image {
    width: 428px;
    height: 248px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    visibility: visible;
    transition: 0.5s;
}

.counter-wrapper {
    display: flex;
    background-color: #bc1e46;
    color: white;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 40%;
    border-radius: 50px;
    font-size: 11px;
}

.cart-wrapper {
    display: flex;
}

.cart-count {
    margin: auto;
}

.cart-inner-wrapper {
    display: flex;
    position: relative;
}


.drop-down-menu-wrapper.language {
    display: none;
}

.header-reverse {
    display: flex;
    border-bottom: 1px solid #dedede;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 320px);
    z-index: 1000;
    background-color: white;
    box-sizing: unset;
    flex-direction: row-reverse;

    .drop-down-menu-wrapper {
        position: relative;
        height: 34px;
        order: 0;
        margin-right: 85px;
        z-index: 1001;

        .drop-down-menu-handler-wrapper {
            position: absolute;
            z-index: 1;

            .drop-down-menu-handler {
                user-select: none;
                display: flex;

                .user-name {
                    position: relative;
                    color: #6e1427;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    white-space: nowrap;
                    max-width: 85px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .drop-down-menu {
                display: none;
            }
        }
    }

    .search-field {
        display: none;
    }

    .navigation-list-wrapper {
        display: none;
        width: 350px;
    }

    .hamburger {
        cursor: pointer;
        order: 3;

        .hamburger-border {
            width: 28px;
            height: 3px;
            background: #bc1e46;
            border-radius: 10px;
        }

        .hamburger-border:nth-child(2) {
            margin: 6px 0;
        }
    }
}

.header {
    display: flex;
    border-bottom: 1px solid #dedede;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 320px);
    z-index: 1000;
    background-color: white;
    box-sizing: unset;

    .drop-down-menu-wrapper {
        position: relative;
        height: 34px;
        order: 0;
        margin-right: 85px;
        z-index: 1001;

        .drop-down-menu-handler-wrapper {
            position: absolute;
            z-index: 1;

            .drop-down-menu-handler {
                user-select: none;
                display: flex;

                .user-name {
                    position: relative;
                    color: #6e1427;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    white-space: nowrap;
                    max-width: 85px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .drop-down-menu {
                display: none;
            }
        }
    }

    .search-field {
        display: none;
    }

    .navigation-list-wrapper {
        display: none;
        width: 350px;
    }

    .hamburger {
        cursor: pointer;
        order: 3;

        .hamburger-border {
            width: 28px;
            height: 3px;
            background: #bc1e46;
            border-radius: 10px;
        }

        .hamburger-border:nth-child(2) {
            margin: 6px 0;
        }
    }
}

#small-dish-tag {
    margin: 0 auto;
    order: 1;
    height: 37px;
}

#cart-icon-tag {
    margin-right: 20px;
    order: 2;
}

@media screen and (min-width: 1024px) {

    .drop-down-menu-wrapper.language {
        display: block;
    }

    .header-reverse {
        padding: 0 320px 0 0;

        .navigation-list {
            direction: rtl;
        }

        .page-image {
            display: initial;
        }

        .page-image--hidden {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s ease, visibility 0s step-end 1.5s;
        }

        .drop-down-menu-wrapper {
            margin-right: 65px;

            .drop-down-menu-handler-wrapper {
                position: initial;
                padding: 0 11px;
                background-color: #ffffff;
                box-shadow: none;
                transition: padding 0.5s, box-shadow 1s;

                .drop-down-menu-handler {
                    .user-name {
                        padding-left: 35px;
                        width: 100%;
                        max-width: 130px;
                        min-width: 130px;
                    }

                    .user-name::before {
                        content: "";
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        left: 0;
                        top: 10px;
                        border: solid #cacfd9;
                        border-width: 3px 3px 0 0;
                        display: inline-block;
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        transition-duration: 0.1s;
                    }
                }

                .drop-down-menu {
                    display: block;
                    list-style: none;
                    height: 0;
                    transform-origin: top;
                    transform: scaleY(0);
                    margin: 0;
                    padding: 0;
                    transition: 0.5s;

                    .drop-down-menu-item {
                        position: relative;
                        line-height: 40px;
                        color: #6e1427;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        padding-left: 35px;
                        text-align: left;
                        cursor: pointer;
                        user-select: none;
                    }

                    .drop-down-menu-item:after {
                        content: "";
                        width: 19.3px;
                        height: 20.7px;
                        position: absolute;
                        top: 10px;
                        left: 0px;
                    }

                    .drop-down-menu-item.profile:after {
                        background: url("../../images/profile_icon_outline_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item.support:after {
                        background: url("../../images/support_icon_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item.faq:after {
                        background: url("../../images/faq_icon.svg") no-repeat;
                        background-size: contain;
                    }

                    .drop-down-menu-item.logout:after {
                        background: url("../../images/logout_icon_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item:not(:last-child) {
                        border-bottom: 1px solid #e1e1e1;
                    }

                    .drop-down-menu-item.enLang:after {
                        background: url("../../images/en.svg") no-repeat;
                    }

                    .drop-down-menu-item.heLang:after {
                        background: url("../../images/he.svg") no-repeat;
                    }
                }
            }
        }

        .drop-down-menu-wrapper.expanded {
            .drop-down-menu-handler-wrapper {
                padding: 7px 11px 0 11px;
                box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
                transition: padding 0.5s, box-shadow 1s;

                .drop-down-menu-handler {
                    .user-name::before {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        transition-duration: 0.5s;
                        top: 15px;
                    }
                }

                .drop-down-menu {
                    transform: scaleY(1);
                    transition: 0.5s;
                }
            }
        }

        .search-field {
            display: block;
            max-width: 250px;
            margin: 0 auto;
            text-align: left;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            background-image: url(../../images/search_icon_small.svg);
            background-position: right -20px top -15px !important;
            background-repeat: no-repeat !important;
            order: 2;
            margin: 0px auto 0px auto;
        }

        .navigation-list-wrapper {
            display: block;
            height: 64px;
            order: 4;

            .navigation-list {
                margin: 0;

                .navigation-list-item {
                    display: inline-block;
                    padding: 0 15px;
                    line-height: 62px;
                    color: #6e1427;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    border-bottom: 3px solid transparent;
                }

                .navigation-list-item.active {
                    font-weight: bold;
                    border-bottom-color: #6e1427;
                }
            }
        }

        .hamburger {
            display: none;
        }
    }

    .header {
        padding: 0 0 0 320px;

        .page-image {
            display: initial;
        }

        .page-image--hidden {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s ease, visibility 0s step-end 1.5s;
        }

        .drop-down-menu-wrapper {
            margin-right: 65px;

            .drop-down-menu-handler-wrapper {
                position: initial;
                padding: 0 11px;
                background-color: #ffffff;
                box-shadow: none;
                transition: padding 0.5s, box-shadow 1s;

                .drop-down-menu-handler {
                    .user-name {
                        padding-left: 35px;
                        width: 100%;
                        max-width: 130px;
                        min-width: 130px;
                    }

                    .user-name::before {
                        content: "";
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        left: 0;
                        top: 10px;
                        border: solid #cacfd9;
                        border-width: 3px 3px 0 0;
                        display: inline-block;
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        transition-duration: 0.1s;
                    }
                }

                .drop-down-menu {
                    display: block;
                    list-style: none;
                    height: 0;
                    transform-origin: top;
                    transform: scaleY(0);
                    margin: 0;
                    padding: 0;
                    transition: 0.5s;

                    .drop-down-menu-item {
                        position: relative;
                        line-height: 40px;
                        color: #6e1427;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        padding-right: 35px;
                        text-align: right;
                        cursor: pointer;
                        user-select: none;
                    }

                    .drop-down-menu-item:after {
                        content: "";
                        width: 19.3px;
                        height: 20.7px;
                        position: absolute;
                        top: 10px;
                        right: 0px;
                    }

                    .drop-down-menu-item.profile:after {
                        background: url("../../images/profile_icon_outline_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item.support:after {
                        background: url("../../images/support_icon_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item.faq:after {
                        background: url("../../images/faq_icon.svg") no-repeat;
                        background-size: contain;
                    }

                    .drop-down-menu-item.logout:after {
                        background: url("../../images/logout_icon_small_red.svg") no-repeat;
                    }

                    .drop-down-menu-item:not(:last-child) {
                        border-bottom: 1px solid #e1e1e1;
                    }


                    .drop-down-menu-item.enLang:after {
                        background: url("../../images/en.svg") no-repeat;
                    }

                    .drop-down-menu-item.heLang:after {
                        background: url("../../images/he.svg") no-repeat;
                    }
                }
            }
        }

        .drop-down-menu-wrapper.expanded {
            .drop-down-menu-handler-wrapper {
                padding: 7px 11px 0 11px;
                box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
                transition: padding 0.5s, box-shadow 1s;

                .drop-down-menu-handler {
                    .user-name::before {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        transition-duration: 0.5s;
                        top: 15px;
                    }
                }

                .drop-down-menu {
                    transform: scaleY(1);
                    transition: 0.5s;
                }
            }
        }

        .search-field {
            display: block;
            max-width: 250px;
            margin: 0 auto;
            text-align: right;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            background-image: url(../../images/search_icon_small.svg);
            background-position: left -20px top -15px !important;
            background-repeat: no-repeat !important;
            order: 2;
        }

        .navigation-list-wrapper {
            display: block;
            height: 64px;
            order: 4;

            .navigation-list {
                margin: 0;

                .navigation-list-item {
                    display: inline-block;
                    padding: 0 15px;
                    line-height: 62px;
                    color: #6e1427;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    border-bottom: 3px solid transparent;
                }

                .navigation-list-item.active {
                    font-weight: bold;
                    border-bottom-color: #6e1427;
                }
            }
        }

        .hamburger {
            display: none;
        }
    }

    #small-dish-tag {
        margin-left: auto;
        order: 8;
        height: 37px;
    }

    #cart-icon-tag {
        order: 1;
    }


    .lang-switcher-auth {
        display: block;
    }
}
