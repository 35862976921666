.footer {
  display: flex;
  position: fixed;
  justify-content: space-evenly;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 20px 0px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.3);
  z-index: 1;
}

.footer-nav {
  width: 25%;
  text-align: center;
  border-bottom: 4px solid transparent;
  font-size: 10px;
  color: black;
  text-decoration: none;
}

.footer-nav.active {
  border-bottom-color: #6e1427;
}

.footer-nav-img {
  text-align: center;
  height: 24px;

  > img {
    height: 100%;
  }
}
