.payment_accordion__block {
  display: flex;
  flex-direction: column;

  .cards-selection {
    width: 100%;
    margin-left: 10px;
  }

  .accordion {
    padding: 16px;
    border-radius: 5px;
    background-color: #f1f1f1;
    margin-bottom: 8px;
  }

  .accordion__title {
    display: flex;
    line-height: 26px;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: var(--dark-red);
    
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .accordion__text {
    margin-top: 10px;
  }

  .card-block__icon {
    height: 24px;
    width: 24px;
    margin: 0 10px;
  }

  .card-block__info-icon {
    height: 24px;
    width: 24px;
    margin-left: 0;
    margin-right: auto;
  }

  .card__amount {
    margin-right: 8px;
    font-size: 16px;
    color: #3E4954;
  }

  .card__text {
    position: relative;
  }
  .customer-payment {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }
  .card__cvv-button {
    border-radius: 4px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    padding: 0 10px;

    color: #838383;

    display: flex;
    align-items: center;

    > img {
      margin-top: 4px;
      margin-left: 10px;
    }
  }

  .card__cvv-input {
    height: auto;
    width: 72px;
    border-radius: 4px;
  }

  .add-card-button {
    display: block;
    margin: 10px 0;
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #bc1e46;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border: 1px solid #bc1e46;
    border-radius: 10px;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .add-card-button:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16px;
    right: 16px;
    background-image: url("../../images/card.svg");
  }

  .--error {
    border: solid 1px #e24047;
  }
}
