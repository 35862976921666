/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #b8becc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
  --red: #bc1e46;
  --dark-red: #6e1427;
}

/* Calendar */

.calendar {
  display: block;
  width: 100%;
  user-select: none;

  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  position: relative;

  * {
    box-sizing: border-box;
  }

  /* GRID */
  //---------------------------------------
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .row-middle {
    align-items: center;
  }

  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-start {
    justify-content: flex-start;
    text-align: right;
  }

  .col-center {
    justify-content: center;
    text-align: center;
  }

  .col-end {
    justify-content: flex-end;
    text-align: left;
  }
  //---------------------------------------

  .calendar-header {
    position: initial;
    padding: 0.5em 30/348 * 100%;
    border-bottom: 0;
    height: 50px;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #7a8599;
  }
  .days {
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #9ba3b3;
  }

  .body {
    .cell {
      position: relative;
      height: 2.5em;
      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      transition: 0.25s ease-out;
    }

    .row:last-child {
      border-bottom: none;
    }

    .cell:last-child {
      border-right: none;
    }

    .cell .number {
      padding: 10px 0;

      font-size: 14px;
      font-weight: bold;
      color: var(--dark-red);
      text-align: center;
    }

    .selected .number {
      background-color: var(--red);
      color: white;
    }

    .disabled .number {
      color: var(--text-color-light);
      pointer-events: none;
    }

    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}
