$dark-red: #6e1427;
$red: #bc1e46;

@mixin form-label() {
  text-align: right;
  color: #6e1427;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.refunded-item {
  color: #bc1e46;
}

.amount-sum {
  direction: ltr;
}

.account-page-wrapper {

  h1 {
    line-height: 36px;
    padding: 16px 0;
    margin: 0;
    text-align: center;
    color: #bc1e46;
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .menu-nav {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    .menu-item {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      position: relative;
      width: 90%;
      min-height: 72px;
      border-radius: 6px;
      border: solid 1px #d3d3d3;
      padding: 20px;
      margin-bottom: 8px;
      color: #6e1427;

      &:active {
        background-color: #FCF6F8;
      }
    }

    .menu-item__icon {
      width: 30px;
      margin-left: 20px;
    }

    .menu-item.active {
      background-color: #fcf6f8;
    }
  }
}

.profile-page-wrapper {
  direction: rtl;
  padding-top: 5px;

  .main-wrapper {
    padding-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
    position: relative;

    .top-row {
      padding-top: 40px;
      padding-right: 80px;
      padding-bottom: 40px;
      display: flex;
    }

    .back-link {
      width: 280px;
      display: flex;
      align-items: center;
    }

    .desktop-title {
      font-size: 42px;
      font-weight: bold;
      text-align: right;
      color: #bc1e46;
    }

    .main-wrapper {
      padding-top: 0;
      padding-left: 150/1600 * 100%;
      padding-bottom: 85px;

      .main-content-wrapper {
        padding-top: 0;
      }

      .configurations {
        margin-top: 0;
      }
    }
  }
}

.profile-content-wrapper {
  padding: 20px 20px;

  h1 {
    line-height: 42px;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #bc1e46;
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
  }

  .divider {
    border-bottom: 1px solid #dedede;
  }

  .card {
    position: relative;
    margin: 5px 0;
    padding: 15px 20px;
    height: 180px;
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  .card-number {
    padding: 20px 0;
    text-align: right;
    border-bottom: 1px solid #e3e3e3;
  }

  .card-expiration {
    padding: 10px 0;
    text-align: right;
  }

  .card-number .parameter:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0px;
    width: 18px;
    height: 13.5px;
    background-image: url("../../images/card_icon.svg");
  }

  .card-expiration .parameter:after {
    content: "";
    position: absolute;
    top: 3px;
    right: 0px;
    width: 19px;
    height: 19px;
    background-image: url("../../images/calendar_icon.svg");
    background-size: contain;
  }

  .card .value {
    position: relative;
    color: #3e4954;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .card .parameter {
    position: relative;
    padding: 0 30px 0 10px;
    color: #6e1427;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  .card .card-remove-button {
    display: block;
    padding-right: 21px;
    position: relative;
    bottom: 0px;
    text-align: center;
    width: 117px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #bc1e46;
    border-radius: 10px;

    margin-left: 0;
    margin-right: auto;

    user-select: none;
    cursor: pointer;
  }

  .card .card-remove-button:after {
    content: "";
    position: absolute;
    top: 14px;
    right: 20px;
    width: 12.1px;
    height: 13px;
    background-image: url("../../images/trash_icon.svg");
  }

  .content-wrapper {
    padding: 25px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
  }

  .content-wrapper .forms-section {
    padding: 10px 0;
  }

  .alerts-form-section-text {
    padding-top: 26px;
  }

  .alerts-form-section-text__header {
    @include form-label();
  }

  .alerts-form-section-text__helper-text {
    font-size: 14px;
    line-height: 20px;
    color: #3E4954;
  }

  .form {
    margin-bottom: 20px;
  }

  .form label {
    @include form-label();
  }

  .form input {
    text-align: right;
    background-position: right 10px center !important;
    background-repeat: no-repeat !important;
    padding-right: 45px;
  }

  .form-switch {
    display: flex;
    align-items: center;

    padding: 16px 0;

    font-size: 14px;
    line-height: 20px;
    color: #3E4954;
    border-bottom: 1px solid #dedede;
  }

  .form-switch__switch {
    margin-left: 0;
    margin-right: auto;
  }

  .forms-save-button {
    display: block;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 10px;
    background-color: #bc1e46;
    color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    min-width: 100px;
    user-select: none;
    cursor: pointer;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 40px 0 0;

    h1 {
      display: block;
      text-align: right;
      color: #565152;
      margin-bottom: 20px;
    }

    h1.mobile-only {
      display: none;
    }

    .divider {
      margin-left: 36/605 * 100%;
    }

    .forms-wrapper {
      margin: 0 25/1045 * 100%;
    }

    .forms-section {
      display: flex;
      flex-wrap: wrap;
    }

    .form {
      width: 33%;
      margin-bottom: 22px;
    }

    .form label {
      margin-bottom: 10px;
    }

    .form input {
      width: calc(100% - 45px);
    }

    .forms-save-button {
      width: fit-content;
      padding: 0 50px;
      margin-left: 0;
      margin-right: auto;
    }

    .form-switch {
      width: calc(33% - 45px);
      margin-bottom: 22px;
      margin-left: 45px;
    }

    .cards-wrapper {
      padding: 32px;
      box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    }

    .card {
      display: flex;
      align-items: center;
      height: auto;

      .card-number {
        padding: 0;
        border: 0;

        &:after {
          content: "";
          border-right: 1px solid #dedede;
          padding: 10px 0;
          margin: 0 10px;
        }
      }

      .card-remove-button {
        bottom: 0;
      }
    }
  }
}

.otp-input {
  width: 100%!important;
}

.common-border-row {
  height: 46px!important;
  margin-top: 1rem;
}

.otp-input {
  height: 54px;
  background: url(../../images/login/otp_icon.svg) no-repeat scroll right 32px
  center;
  padding-right: 46px;
  width: calc(100% - 46px);
  border: 0;
}

.active {
  background-color: #f1f1f1;
}

.divider {
  border-bottom: 1px solid #e3e3e3;
}















.input-field {
  label {
    font-size: 14px;
    letter-spacing: normal;
    text-align: right;
    color: $dark-red;
    margin: 10px 20px 10px 0;
  }

  input,
  textarea {
    width: calc(100% - 49px);
    text-align: right;
    background-position: right 10px center !important;
    background-repeat: no-repeat !important;
    padding-right: 45px;

    &::placeholder {
      font-size: 14px;
      text-align: right;
      color: #b4bbca;
    }
  }

  textarea {
    border-radius: 26px;
    background-position: right 10px top 9px !important;
    border: solid 1px #e3e3e3;
    min-height: 115px;
    color: #666f80;
    padding: 9px 45px 9px 10px;
    width: calc(100% - 49px - 10px);
    font-family: "OpenSansHebrew";

    overflow: auto;
    outline: none;
    box-shadow: none;

    resize: none;

    font-size: 14px;
    line-height: 1.43;
  }
}

.info-image {
  max-width: 250px;
  margin: 1rem
}

.forms-save-button {
  margin-top: 0.5rem;
}

.forms-link-button {
  color: #6e1427;
  cursor: pointer;
  margin-left: 1rem;
}

.otp-form-resend {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
}




.bank-container {
  .drop-down-wrapper {
    position: relative;
    height: 40px;
  }

  .select.drop-down-wrapper {
    cursor: pointer;
  }

  .drop-down {
    width: 100%;
    background-color: #ffffff;
    transition: 0.5s;
    z-index: 1051;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 5%;
      top: 14px;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition-duration: 0.1s;
    }
  }

  .drop-down-contents {
    transform-origin: top;
    transform: scaleY(0);
    transition: 0.5s;
    max-height: 0;
    border-radius: 100px;
  }
  .drop-down-option {
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
    background-color: #ffffff;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .drop-down {
    input,
    textarea {
      width: calc(100% - 49px);
      text-align: right;
      background-position: right 10px center !important;
      background-repeat: no-repeat !important;
      padding-right: 45px;

      &::placeholder {
        font-size: 14px;
        text-align: right;
        color: #b4bbca;
      }
    }
  }

  .drop-down.expanded {
    position: relative!important;
    width: 100%;

    transition: 0.5s;
    border-radius: 19px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.07);
    overflow: hidden;

    &:before {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition-duration: 0.1s;
    }

    .drop-down-contents {
      transform: scaleY(1);
      transition: 0.5s;
      max-height: 200px!important;
      overflow-y: auto;
    }
  }
  .drop-down-wrapper {
    position: relative;
    height: 40px;
  }
  .drop-down {
    width: 100%;
    background-color: #ffffff;
    transition: 0.5s;
    z-index: 1051;
    position: initial;
    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 5%;
      top: 14px;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition-duration: 0.1s;
    }
  }

  .drop-down-contents {
    transform-origin: top;
    transform: scaleY(0);
    transition: 0.5s;
    max-height: 0;
    border-radius: 2px!important;
  }

  .drop-down-option {
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
    background-color: #ffffff;

    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .info-image {
    max-width: 250px;
    margin: 1rem
  }

  .forms-save-button {
    margin-top: 0.5rem;
  }

  .forms-link-button {
    color: #6e1427;
    cursor: pointer;
    margin-left: 1rem;
  }

  .otp-form-resend {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
  }
}
