$dark-red: #6e1427;
$red: #bc1e46;
$dark: #3e4954;
$purplish-brown: #565152;
%primary-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: right;
}
%secondary-text {
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
}
.lang-en {
    %primary-text {
        text-align: left;
    }

    %secondary-text {
        text-align: left;
    }

    .input-calendar__input {
        text-align: left;
    }
}
.summary {
  padding: 16px;
  background-color: #f1f1f1;
  border-bottom: 2px solid darkgray;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.summary-item-name {
  font-weight: bold;
  margin-right: 1rem;
  margin-left: 1rem;
}
// %details-info-text {
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   text-align: right;
//   color: #666f80;
// }

//----------------------------------------------------------
.mr-0 {
  margin-right: 0 !important;
}

.history-list-wrapper {
  margin-left: 12px;
  margin-right: 12px;
}

@media screen and (min-width: 1024px) {
  
  .history-main-wrapper {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .history-desktop-dropdown {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }

  .history-list-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

.w-100 {
  width: 100%;
}

.input-calendar-wrapper {
  position: relative;
  @media screen and (min-width: 1024px) {
    min-width: 397/1139 * 100%;
    margin-right: 0;
    margin-left: auto;
  }
}
.input-calendar-wrapper-reverse {
    position: relative;

    @media screen and (min-width: 1024px) {
        min-width: 397/1139 * 100%;
        margin-right: auto;
        margin-left: 0;
    }
}

.input-calendar {
  position: relative;
  border-radius: 8px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  padding-left: 38px;
  margin: 16px 0;
}

.input-calendar__input {
  border: 0;
  width: calc(100% - (20 / 346 * 100%));
  height: 100%;
  height: 40px;
  padding-right: 16/346 * 100%;
  text-align: right;
  color: #3e4954;
  
  &:disabled {
    background-color: transparent;
  }
}

.input-calendar__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 40px;
  height: 32px;
}

@media screen and (min-width: 1024px) {
  .input-calendar {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .input-calendar__input {
    height: 58px;
    font-size: 22px;
    width: calc(100% - 38px);
    padding-left: 55px;
  }

  .input-calendar__icon {
    width: 55px;
    height: 38px;
  }
}

.calendar-wrapper {
  position: absolute;
  right: 50%;
  width: 100%;
  transform: translateX(50%);
  z-index: 1000;

  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 30px 0 rgba(62, 73, 84, 0.29);
}
//----------------------------------------------------------

.order-history {
  .modal-overlay {
    z-index: 1000;
  }

  .modal-content {
    width: calc((100% - (31 / 375 * 100% * 2)) - (20px * 2));
    padding: 20px;
  }

  .rating-window {
    .input-wrapper > div {
      width: 100%;
    }
  }

  .input-field-wrapper > input {
    text-align: right;

    &::placeholder {
      text-align: right;
    }
  }

  //----------------------------------------------------------
  .confirmation-window-tag {
    img {
      width: 66px;
      height: 66px;
    }
  }

  .confirmation-window__close-icon {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .confirmation-window__primary-text {
    margin: 0;
    font-size: 25.6px;
    font-weight: bold;
    line-height: 1.95;
    text-align: center;
    color: $red;
  }
  .confirmation-window__secondary-text {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #666f80;
  }
  .confirmation-window__footer button {
    width: calc((50%) - 8px);
    min-height: 47px;
    border-radius: 10px;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }
  //----------------------------------------------------------

  .close-icon {
    position: absolute;
    left: 21px;
    top: 18px;
  }

  .info-icon {
    margin: 0 0 0 10px;
    display: inline-block;
    width: 16px;
    text-align: center;
  }

  // Details window styles
  //----------------------------------------------------------
  // .details-info__title {
  //   font-size: 20px;
  //   font-weight: bold;
  //   font-stretch: normal;
  //   font-style: normal;
  //   letter-spacing: normal;
  //   text-align: right;
  //   color: $red;
  // }

  // .details-info__general-info {
  //   display: table;
  //   width: fit-content;
  // }

  // .details-info__general-info__row {
  //   display: table-row;

  //   > div {
  //     display: table-cell;
  //   }
  // }

  // .details-info__general-info__row__info-label {
  //   padding-left: 6px;
  // }

  // .details-info-text {
  //   @extend %details-info-text;
  // }
  // .details-info__name {
  //   @extend %primary-text;
  // }
  // .details-info__meal-type {
  //   border-radius: 30px;
  //   background-color: $red;

  //   font-size: 12px;
  //   font-weight: normal;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.67;
  //   letter-spacing: normal;
  //   text-align: center;
  //   color: #ffffff;

  //   padding: 0 6px;
  //   display: inline-block;
  //   margin: 18px 0;
  // }

  // .option-group-icon {
  //   width: 26px;
  //   text-align: center;
  // }
  //----------------------------------------------------------

  //----------------------------------------------------------
  .rating-window__tag {
    text-align: center;
  }
  .rating-window__title {
    font-size: 25.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: $red;
    margin-top: 12px;
  }
  .rating-window__rating-row {
    display: flex;
    justify-content: center;
    border-top: solid 1px #e3e3e3;
    border-bottom: solid 1px #e3e3e3;
    padding: 18px 0;
    margin: 18px 0;
  }
  .rating-star {
    margin: 0 5px;
  }
  .rating-window__button-row {
    display: flex;
  }
  .rating-window__button {
    width: 146/348 * 100%;
    margin-left: 0;
    margin-right: auto;

    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    background-color: $red;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    font-size: 16px;
  }

  .input-type-icon {
    right: 15px;
  }
  //----------------------------------------------------------

  .order-info__order-number {
    margin: 24px 0 14px;
    .number-prefix {
      color: #969ba0;
    }
  }

  .order-info__order-day {
    // margin: 24px 0 14px;
    font-size: 18px;
    font-weight: bold;
    color: #3e4954;
  }

  .line {
    display: flex;
    align-items: center;

    margin-right: 8px;

    font-size: 16px;
    text-align: right;
    color: $purplish-brown;
  }

  .img-partner {
    font-weight: bold;
    margin: 0 6px;
  }

  .order-info {
    // padding: 12px 16/349 * 100%;
    object-fit: contain;
    // border-radius: 10px;
    // background-color: #f9f9f9;
    // margin-top: 10px;
  }

  .details {
    border-radius: 4px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    border: solid 1px $dark;
    margin-right: auto;
    margin-left: 0;

    padding: 0px 16px;
    font-size: 12px;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
  }

  .order-info__date {
    // @extend %primary-text;
    color: $dark;

    display: flex;
    align-items: center;
  }
  .order-info__name {
    @extend %primary-text;
    color: $dark;
  }

  .status_block {
    width: fit-content;
    background-color: #FFA53B;
    border-radius: 4px;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;

    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 12px;
  }
  .status_block--cancelled {
    background-color: #FF0000;
  }
  .status_block--delivered {
    background-color: #25B700;
  }
  .status_block--refunded {
    background-color: #9D00B7;
  }
  .status_block__icon {
    margin-left: 4px;
  }

  .order-info__info {
    @extend %secondary-text;
    color: $dark;
  }

  .order-info__info--red {
    color: $red;
  }

  .order-info__info--dark {
    color: $dark;
  }

  .order-info__button-row {
    display: flex;
    justify-content: space-around;

    margin-top: 16px;
  }

  .order-info__button {
    width: 155/349 * 100%;
    height: auto;
    min-height: 47px;
    padding: 0 14px 0 12px;
    border-radius: 10px;
    box-shadow: 0 4px 30px 0 rgba(110, 20, 39, 0.12);
    border: solid 1px $red;
    color: $red;
    background-color: transparent;

    font-weight: normal;
    font-size: 16px;

    display: flex;
    align-items: center;

    .img-partner {
      font-weight: normal;
      margin: 0 auto;
    }
  }

  .rating-display {
    position: relative;
  }

  .rating-display__number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
  }

  .divider {
    border: 1px solid #cacaca;
    margin: 16px 0;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 1em;

    label {
      margin-bottom: 8px;
      font-size: 14px;
      color: #6e1427;
    }

    input {
      height: 49px;
      padding-right: 40px;
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 0;

    .rating-window {
      .modal-content {
        width: 310px;
        padding: 20px;
      }
    }

    .order-info__title {
      display: flex;
      align-items: center;
      width: 50%;
      padding: 16px 0;
    }
    .order-info__actions {
      display: flex;
      align-items: center;
      
      margin-left: 0;
      margin-right: auto;
    }

    .status_block {
      margin-bottom: 0;
      margin-left: 3%;
    }

    .order-info__button-row {
      justify-content: flex-end;
      margin-top: 0;
    }

    .order-info__button {
      width: auto;
      min-width: 164px;
      height: 100%;
      margin: 0 4px;

      > img {
        margin-left: 10px;
      }
    }

    .order-info__date {
      display: table-cell;
      font-weight: normal;
    }

    .order-info__remarks {
      margin-left: 20px;
    }

    .content-wrapper {
      display: none;
    }
  }
}
