.summary-order {
  background: #C5625D;
  position: fixed;
  z-index: 2;
  bottom: 60px;
  left: 0;
  right: 0;
  color: white;
  display: flex;
  padding: 0.5rem;
}

.label {
  padding-right: 2rem;
}

.counter {
  display: inline-block;
  border-radius: 50%;
  background: white;
  height: 1.5rem;
  width: 1.5rem;
  color: #6E1427;
  text-align: center;
}

.sum {
  width: 50%;
  text-align: left;
}
