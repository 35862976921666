.item_accordion__block {
  display: flex;
  flex-direction: column;

  .accordion {
    padding: 12px 16px;
    border-radius: 10px;
    background-color: #F9F9F9;
    margin-bottom: 12px;
  }

  .accordion__title {
    position: relative;
    
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      border: solid #cacfd9;
      border-width: 2px 2px 0 0;
      display: inline-block;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition-duration: 0.1s;

      left: 0;
      top: 8px;
    }
  }

  .active .accordion__title {
    &:before {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transition-duration: 0.1s;
    }
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .accordion__text {
    margin-top: 10px;
  }

  @media screen and (min-width: 1024px) {
    .accordion {
      padding-right: 60px;
    }

    .accordion__title {
      display: flex;
      align-items: center;

      &:before {
        right: -30px;
        top: 50%;
        transform: translateY(-50%) rotate(135deg);
      }
    }
  }
}
